/*

npm i -g npm-check-updates
ncu -u
npm install

*/

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;

let a, s, pusher_app_key;

switch (hostname) {
	case 'localhost':
	case '127.0.0.1':
		a = 'http://localhost/api_instituto_ideas/public/api/';
		s = 'http://localhost/api_instituto_ideas/public/';
		pusher_app_key = 'c83734b103069350dc25';
		break;
	case 'sistema.institutoideas.edu.mx':
	case 'https://sistema.institutoideas.edu.mx':
	case 'http://sistema.institutoideas.edu.mx':
		a = 'https://apiideas.institutoideas.edu.mx/public/api/';
		s = 'https://apiideas.institutoideas.edu.mx/public/';
		pusher_app_key = 'c83734b103069350dc25';
		break;
	case 'ideas.acrescotec.com':
	case 'https://ideas.acrescotec.com':
		a = 'https://api-ideas.acrescotec.com/public/api/';
		s = 'https://api-ideas.acrescotec.com/public/';
		pusher_app_key = 'c83734b103069350dc25';
		break;
	default:
		a = protocol + '//api' + hostname + '/public/api/';
		s = protocol + '//api' + hostname + '/public/';
		pusher_app_key = 'ff15eef8898f41c63fcf';
}

const api = a;
const src = s;

const basename = hostname === 'localhost' ? (Number(port) === 3000 || Number(port) === 3001 || Number(port) === 3002 || Number(port) === 3003 ? '' : '/institutoideas/build/') : '/';

export const CONFIG = {
	prefix: 'institutoideas',
	id_project: 2,
	api: api,
	src: src,
	basename: basename,
	foto_default: src + 'file/perfil/default-perfil.svg',
	portada_default: src + 'file/portada/default-portada.svg',
	menu: 1,
	debug: true,
	pusher_app_key: pusher_app_key,
	pusher_app_cluster: 'us2',
	pusher_app_force_tls: true,
	pusher_app_log_to_console: true,
	segundo_confirmar_token_expirado: 30,
	tipo_menu_contextual: 2,
	onesignal_hostname_prod: 'ideas.mlp.mx',
	onesignal_app_id_prod: '879ad787-9142-4e84-acfc-ce411fca10a3',
	onesignal_app_id_test: '812bedc8-849f-4a46-b4a5-c2d18f7af0f3',
	titulo_alert_confirm: 'Instituto Ideas',
	
	DROPBOX_TOKEN: "3A5dcCeX1PQAAAAAAAAAAaAxqMVXbH2VSnQmWKNSnW0BInGrvQ18BEaxO4U0NCux",
	DROPBOX_API: "https://api.dropboxapi.com/2/files/",
	DROPBOX_CONTENT: "https://content.dropboxapi.com/2/files/",
};
