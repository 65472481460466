import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const AdministrativoService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Administrativo_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		
		let fecha_nacimiento = form.fecha_nacimiento ? DateFormat.FormatSql(form.fecha_nacimiento): null;
		let fecha_ingreso = form.fecha_ingreso ? DateFormat.FormatSql(form.fecha_ingreso): null;
		
		let params = {
			id_administrativo: form.id_administrativo || '',
			curp: form.curp || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			id_cat_sexo: form.id_cat_sexo || '',
			correo_electronico: form.correo_electronico || '',
			fecha_nacimiento: fecha_nacimiento,
			domicilio: form.domicilio || '',
			fecha_ingreso: fecha_ingreso,
			tipo_categoria: form.tipo_categoria || '',
			id_cat_curso: form.id_cat_curso || '',
			id_cat_tipo_plaza: form.id_cat_tipo_plaza || '',
			formacion_academica: form.formacion_academica || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Administrativo_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_nacimiento = form.fecha_nacimiento ? DateFormat.FormatSql(form.fecha_nacimiento): null;
		let fecha_ingreso = form.fecha_ingreso ? DateFormat.FormatSql(form.fecha_ingreso): null;
		
		let params = {
			id_administrativo: form.id_administrativo || '',
			curp: form.curp || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			id_cat_sexo: form.id_cat_sexo || '',
			correo_electronico: form.correo_electronico || '',
			fecha_nacimiento: fecha_nacimiento,
			domicilio: form.domicilio || '',
			fecha_ingreso: fecha_ingreso,
			tipo_categoria: form.tipo_categoria || '',
			id_cat_curso: form.id_cat_curso || '',
			id_cat_tipo_plaza: form.id_cat_tipo_plaza || '',
			formacion_academica: form.formacion_academica || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Administrativo_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_administrativo) => {let params = {
			id_administrativo: id_administrativo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Administrativo_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
