import {CONFIG} from '../Config/Config';
import {FieldsJs, hideSpinner, showSpinner} from '../General/General';
import {ReactLocalStorageService} from '../ReactLocalStorageService/ReactLocalStorageService';
import {Dropbox} from "dropbox";
import axios from 'axios/index';
import {HttpRequest} from "../HttpRequest/HttpRequest";


const imprimir = (strung, obj, type) => {
	let dato = [];
	if (type) {
		dato = [
			"%c%s%c%s\n",
			"color: white; background: gray; font-size: 12px;font-weight: bold;letter-spacing: 10px;",
			" " + strung,
			"color: #30568C; background: #FAFAFA; font-size: 12px;font-weight: bold;",
			obj
		];
		window.console.log(
			dato[0],
			dato[1],
			dato[2],
			dato[3],
			JSON.stringify(dato[4], null, 2)
		);
	} else {
		dato = [
			"%c%s%c\n",
			"color: white; background: gray; font-size: 12px;font-weight: bold;letter-spacing: 10px;",
			" " + strung,
			"color: #30568C; background: #FAFAFA; font-size: 12px;font-weight: bold;",
			obj
		];
		window.console.log(
			dato[0],
			dato[1],
			dato[2],
			dato[3],
			dato[4]
		);
	}
};

export const DropboxApi = {
	
	List: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		
		return new Promise((resolve, reject) => {
			
			dbx.filesListFolder({
				path: path
			}).then(response => {
				console.log("DROPBOX::: ", response);
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				
				resolve(response);
			}).catch((error) => {
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
	
	Upload: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		/*if (setting.spinner) {
			showSpinner('spinner');
		}*/
		
		return new Promise((resolve, reject) => {
			dbx.filesUpload({
				path: path,
				contents: contents,
				mode: mode ? mode : 'overwrite'
			}).then(function (response) {
				/*if (setting.spinner) {
					hideSpinner('spinner', time);
				}*/
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				resolve(response);
			}).catch(function (error) {
				
				/*if (setting.spinner) {
					hideSpinner('spinner', time);
				}*/
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
	
	Download: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		const arc = path.split('.');
		const nom = path.split('/');
		
		return new Promise((resolve, reject) => {
			dbx.filesDownload({
				path: path
			}).then(function (response) {
				console.log("DROPBOX::: ", response);
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				
				const url = URL.createObjectURL(response.fileBlob);
				const a = document.createElement('a');
				a.href = url;
				a.download = nom[nom.length - 1] + '.' + arc[arc.length - 1] || 'download';
				const clickHandler = () => {
					setTimeout(() => {
						URL.revokeObjectURL(url);
					}, 150);
				};
				a.addEventListener('click', clickHandler, false);
				a.click();
				
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				
				resolve(response);
			}).catch(function (error) {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
	
	GenerateZip: (path, carpeta) => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		const time = 500;
		
		showSpinner('spinner');
		
		const arc = path.split('/') || [];

		/*let path_zip = '';
		let path_zip_name_archive = '';
		
		for (let i = 0; i < arc.length; i++) {
			if (i > 0) {
				path_zip = path_zip + (!carpeta ? '/' + arc[i] : i === arc.length - 1 ? '/' : '/' + arc[i]);
				path_zip_name_archive = path_zip_name_archive + (!carpeta ? '-' + arc[i] : i === arc.length - 1 ? '' : arc[i] + '-');
			}
		}
		console.log("ARCHIVO::: ", path_zip, path_zip_name_archive);
		
		
		const arcdoc = path.split('.') || [];
		if (arcdoc[arcdoc.length - 1] === 'xml' || arcdoc[arcdoc.length - 1] === 'pdf') {
			path_zip = path;
		}
		*/
		let data = {
			"data": {
				ruta: path
			}
		};
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [POST]:\n\n" + CONFIG.api + "dropbox_export_zip" + "\n" + JSON.stringify(data, null, 2) + '\n');
		}
		
		return new Promise((resolve, reject) => {
			axios.post(CONFIG.api + "dropbox_export_zip", data, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				timeout: Cfg.timeout || undefined,
			}).then(response => {
				//console.log("AXIOS ::: ", response);
				hideSpinner('spinner', time);
				let params = {
					path: path,
				};
				HttpRequest.export('dropbox_export_zip_get', ['dropbox_export_zip_get'], params, false, false, false, "ZIP");

				//window.open(CONFIG.src + 'dropbox_export_zip_get/' + json_decode(path), '_blank');
				resolve(true);
			}).catch(function (error) {
				
				hideSpinner('spinner', time);
				
				let errors = {};
				
				if (!error) {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				} else {
					errors = {
						success: false,
						codigo_api: 400,
						mensaje: "Error al procesar los datos",
						error: error
					}
				}
				
				if (CONFIG.debug) {
					window.console.error(errors);
				}
				
				reject(errors);
				
			});
		});
	},
	
	Open: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		const arc = path.split('.');
		let typeDoc = '';
		if (arc[arc.length - 1] === 'pdf') {
			typeDoc = 'application/pdf';
		} else if (arc[arc.length - 1] === 'xml') {
			typeDoc = 'text/plain';
		} else {
			typeDoc = 'image/*';
		}
		
		return new Promise((resolve, reject) => {
			dbx.filesDownload({
				path: path
			}).then(function (response) {
				console.log("DROPBOX::: ", response);
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				
				//const url = URL.createObjectURL(response.fileBlob);
				/*var file = new Blob([response.fileBlob], {type: typeDoc, endings: 'native'});
				console.log("FILE:::", file);
				var reader = new FileReader();
				reader.onload = function() {
					console.log("RESULTADO:::", reader.result);
					var parser = new DOMParser();
					file = parser.parseFromString(reader.result,"text/xml");
					
					var fileURL = URL.createObjectURL(file);
					window.open(fileURL, '', 'width=900, height=600');
					
				};
				reader.readAsText(file);*/
				
				var file = new Blob([response.fileBlob], {type: typeDoc, endings: 'native'});
				var fileURL = URL.createObjectURL(file);
				
				if (typeDoc === 'image/*') {
					const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
					const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;
					
					const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
					const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
					
					const systemZoom = width / window.screen.availWidth;
					const left = (width - 600) / 2 / systemZoom + dualScreenLeft;
					const top = (height - 600) / 2 / systemZoom + dualScreenTop;
					
					var newWindow = window.open("", "pictureViewer",
						"location=no, directories=no, fullscreen=no, " +
						"menubar=no, status=no, toolbar=no, width=600, height=600, top="+ top +", left="+left+" scrollbars=no");
					newWindow.document.writeln("<html>");
					newWindow.document.writeln("<body style='margin: 0 0 0 0;'>");
					newWindow.document.writeln("<a href='javascript:window.close();'>");
					newWindow.document.writeln("<img src='" + fileURL + "' alt='Click to close' id='bigImage'/>");
					newWindow.document.writeln("</a>");
					newWindow.document.writeln("</body></html>");
					newWindow.document.close();
				} else {
					window.open(fileURL, '', 'width=900, height=600');
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				
				resolve(response);
			}).catch(function (error) {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
	
	Delete: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		const arc = path.split('.');
		
		return new Promise((resolve, reject) => {
			dbx.filesDelete({
				path: path
			}).then(function (response) {
				console.log("DROPBOX::: ", response);
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				
				resolve(response);
			}).catch(function (error) {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
};
