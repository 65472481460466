import React, {Component, Fragment} from 'react';

import Header from '../../../Include/Header/Header';
import Footer from '../../../Include/Footer/Footer';

import CicloEscolar from './CicloEscolar';
import './CicloEscolar.css';

import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import TokenNoValido from "../../../../componets/Include/TokenNoValido/TokenNoValido";
import {GetTipoMenu} from "../../../../settings/General/General";

class CicloEscolarIndex extends Component {
	
	state = {
		access: false
	};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			access: null
		};

	}
	
	render() {
		return (
			<div className='CicloEscolarIndex'>
				
				<Header/>
				
				<div className={`Container ${GetTipoMenu()}`}>
						
						<Fragment>
							
							<CicloEscolar/>
							
							<Footer/>
						
						</Fragment>
				
				</div>
			
			</div>
		);
	}
}

export default CicloEscolarIndex;
