import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {FieldsJs} from "../../../settings/General/General";
import {NavigateNext} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import DivSvg from "../../Include/MiniComponents/DivSvg";
import sexo from '../../../assets/img/icon-catalogo/sexo.svg';
import grupo_venta from '../../../assets/img/icon-catalogo/grupo_venta.svg';
import menu_alta_modulo from '../../../assets/img/icon-catalogo/menu_alta_modulo.svg';
import usuarios_roles_y_permisos from '../../../assets/img/icon-catalogo/usuarios_roles_y_permisos.svg';
import usuario_enviar_reporte from '../../../assets/img/icon-catalogo/usuario_enviar_reporte.svg';
import tipo_de_usuarios from '../../../assets/img/icon-catalogo/tipo_de_usuarios.svg';
import giro_de_empresas from '../../../assets/img/icon-catalogo/giro_de_empresas.svg';
import metodo_de_pago from '../../../assets/img/icon-catalogo/metodo_de_pago.svg';
import como_te_enteraste from '../../../assets/img/icon-catalogo/como_te_enteraste.svg';
import formas_de_pago from '../../../assets/img/icon-catalogo/formas_de_pago.svg';
import tipo_de_requerimientos from '../../../assets/img/icon-catalogo/tipo_de_requerimientos.svg';
import categorias from '../../../assets/img/icon-catalogo/categorias.svg';
import area_trabajo from '../../../assets/img/icon-catalogo/area_trabajo.svg';
import especialidades from '../../../assets/img/icon-catalogo/especialidades.svg';
import semestre from '../../../assets/img/icon-catalogo/semestre.svg';
import ciclo_escolar from '../../../assets/img/icon-catalogo/ciclo_escolar.svg';
import generacion from '../../../assets/img/icon-catalogo/generacion.svg';
import modalidad from '../../../assets/img/icon-catalogo/modalidad.svg';

class Catalogos extends Component {
	
	
	permisos = (cat) => {
		
		const Usr = ReactLocalStorageService.get('Usr') || {};
		
		let permitido = false;
		
		switch (cat) {
			case 'cat_modalidad':
			case 'cat_ciclo_escolar':
			case 'cat_generacion':
			case 'cat_semestre':
			case 'cat_especialidades':
			case 'cat_sexo':
			case 'cat_area_trabajo':
			case 'cat_sexo':
			case 'cat_grupo':
			case 'cat_categoria':
			case 'usuario_enviar_reporte':
			case 'cat_prestacion':
				permitido = FieldsJs.inArray([1, 2], Usr.id_cat_tipo_usuario);
				break;
			case 'roles_permisos':
				permitido = FieldsJs.inArray([1], Usr.id_cat_tipo_usuario);
				break;
			case 'menu_submenu':
				permitido = FieldsJs.inArray([1], Usr.id_cat_tipo_usuario);
				break;
			default:
		}
		
		return permitido;
	};
	
	
	render() {
		
		const btn_text_icon = (
			<Fab variant="extended" size="small" color="default" className={'px-10'}
			     style={{marginTop: "10px", padding: "0px 15px", height: "25px"}}>
				Configurar <NavigateNext className={'px-14'}/>
			</Fab>
		);
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<div style={{width: "100%"}} align={'center'}>
					<Typography variant={'h6'} className={'margin-30-T margin-30-B vertical-inline v-center'} style={{
						color: 'white',
						backgroundColor: 'gray',
						borderRadius: '10px',
						padding: '5px 30px',
						lineHeight: "20px",
						fontSize: "20px",
					}}>
						Configura los catálogos que se usaran en el sistema
					</Typography>
				</div>
				
				<div className={'grid-20-150-px'}>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-200-px'}>
							<div className={'w-100 h-100'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={como_te_enteraste} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Como te enteraste
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/comoteenteraste'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-200-px'}>
							<div className={'w-100 h-100'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={formas_de_pago} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Formas de pagos
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/formapago'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-200-px'}>
							<div className={'w-100 h-100'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={metodo_de_pago} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Métodos de pagos
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/metodopago'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-200-px'}>
							<div className={'w-100 h-100'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={giro_de_empresas} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Giro empresa
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/giroempresa'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-200-px'}>
							<div className={'w-100 h-100'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={tipo_de_usuarios} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Tipo de usuarios
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/tipousuario'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-200-px'}>
							<div className={'w-100 h-100'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={tipo_de_requerimientos} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Tipo de requerimientos
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/tiporequerimiento'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					{this.permisos('cat_sexo') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={sexo} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Sexo
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/genero'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_grupo') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={grupo_venta} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Grupos/Categorias de ventas
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/grupo'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_categoria') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={categorias} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Categoría
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/categoria'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('usuario_enviar_reporte') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={usuario_enviar_reporte} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Usuario enviar reporte
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/usuarioenviarreporte'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('menu_submenu') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={menu_alta_modulo} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Menus (Alta de módulos)
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/menus'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('roles_permisos') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={usuarios_roles_y_permisos} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Roles y permisos
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/rolespermisos'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_semestre') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={semestre} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Semestre
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/semestre'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_especialidades') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={especialidades} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Especialidades
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/especialidades'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_ciclo_escolar') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={ciclo_escolar} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Ciclo Escolar
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/ciclo_escolar'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_generacion') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={generacion} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Generación
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/generacion'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_modalidad') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-200-px'}>
								<div className={'w-100 h-100'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={modalidad} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Modalidad
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/modalidad'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
				
				</div>
			
			</div>
		);
	}
}

export default Catalogos;
