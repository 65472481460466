import React, {Component} from 'react';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import Checkbox from "@material-ui/core/Checkbox/index";
import FormGroup from "@material-ui/core/FormGroup/index";
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import BoxSelectFile from "../../../../Include/MiniComponents/BoxSelectFile";
import Grid from "@material-ui/core/Grid";

class ModalCategoria extends Component {
	
	render() {
		
		return (
			<div>
				
				<Dialog open={this.props.modal.open} onClose={() => this.props.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{this.props.modal.title} Categoría
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									autoFocus
									margin="dense"
									name="clave"
									label="Clave del producto"
									type="text"
									fullWidth
									defaultValue={this.props.form.clave}
									onChange={this.props.handleChange}
									disabled={this.props.modal.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									autoFocus
									margin="dense"
									name="categoria"
									label="Categoría"
									type="text"
									fullWidth
									defaultValue={this.props.form.categoria}
									onChange={this.props.handleChange}
									disabled={this.props.modal.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									autoFocus
									margin="dense"
									name="descripcion"
									label="Descripcion"
									type="text"
									fullWidth
									defaultValue={this.props.form.descripcion}
									onChange={this.props.handleChange}
									disabled={this.props.modal.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BoxSelectFile
									FormatAccepted={["image/png", "image/jpeg"]}
									MegaByte={5}
									// button={(
									// 	<AddOutlined/>
									// )}
									label={(
										<b className={'vertical-inline v-center text-blue'}>Imagen categoría</b>
									)}
									style={{
										color: 'black',
										height: '115px'
									}}
									item={{
										base64: this.props.form.imagen_base64,
										base64Tipo: this.props.form.imagen_base64Tipo,
										archivo: this.props.form.imagen_archivo,
										formato: this.props.form.imagen_formato,
									}}
									onChange={(r) => {
										this.props.handleChange({
											target: {
												name: 'imagen_base64',
												value: r.base64
											}
										});
										this.props.handleChange({
											target: {
												name: 'imagen_base64Tipo',
												value: r.base64Tipo
											}
										});
										this.props.handleChange({
											target: {
												name: 'imagen_archivo',
												value: r.archivo
											}
										});
										this.props.handleChange({
											target: {
												name: 'imagen_formato',
												value: r.formato
											}
										});
									}}
									showSnackBars={this.props.showSnackBars}
									disabled={this.props.modal.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='activo' checked={this.props.form.activo}
											          onChange={this.props.handleChange} value="activo" color="primary"
											          disabled={this.props.modal.tipo === 'view'}/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.props.closeModal()} color="primary">
							{this.props.modal.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.props.modal.tipo !== 'view' ? (
							<Button onClick={() => this.props.save()} color="primary">
								{this.props.form.id_cat_categoria > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

export default ModalCategoria;
