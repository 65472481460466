import React, {Component, Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import App from './componets/App';
import LogInIndex from './componets/PageFull/LogIn';
import RegistroIndex from './componets/PageFull/Registro';
import RegistroDatosPersonalesIndex from './componets/PageFull/RegistroDatosPersonales';
import RecuperarIndex from './componets/PageFull/Recuperar';
import VerificarIndex from './componets/PageFull/Verificar';
import CambiarContrasenaIndex from './componets/PageFull/CambiarContrasena';
import CatalogosIndex from './componets/PageContent/Catalogos';
import SexoIndex from './componets/PageContent/Cat/Sexo';
import GrupoIndex from './componets/PageContent/Cat/Grupo';
import ComoTeEnterasteIndex from './componets/PageContent/Cat/ComoTeEnteraste';
import FormaPagoIndex from './componets/PageContent/Cat/FormaPago';
import MetodoPagoIndex from './componets/PageContent/Cat/MetodoPago';
import GiroEmpresaIndex from './componets/PageContent/Cat/GiroEmpresa';
import TipoUsuarioIndex from './componets/PageContent/Cat/TipoUsuario';
import TipoRequerimientoIndex from './componets/PageContent/Cat/TipoRequerimiento';
import MenuSubMenuIndex from './componets/PageContent/Cat/MenuSubMenu';
import RolesPermisosIndex from './componets/PageContent/Cat/RolesPermisos';
import UsuarioEnviarReporteIndex from './componets/PageContent/Cat/UsuarioEnviarReporte';
import CategoriaIndex from './componets/PageContent/Cat/Categoria';
import UsuariosIndex from './componets/PageContent/Usuarios';
import ProveedorIndex from './componets/PageContent/Proveedor';
import ProductoIndex from './componets/PageContent/Producto';
import ClienteIndex from './componets/PageContent/Cliente';
import HomeIndex from './componets/PageContent/Home';
import TableroIndex from "./componets/PageContent/Tablero";
import PerfilIndex from './componets/PageContent/Perfil';
import MonitorIndex from './componets/PageContent/Monitor';
import {NewPreCotizacionIndex} from './componets/PageContent/PreCotizacion';
import {VentasCotizacionIndex, VentasNotaSencillaFacturaInternaIndex} from './componets/PageContent/Ventas';
import {OrdenDeVentaIndex1, OrdenDeVentaIndex2, OrdenDeVentaIndex3, OrdenDeVentaIndex4} from './componets/PageContent/OrdenDeVenta';
import ComprasIndex from './componets/PageContent/Compras';
import Page404Index from './componets/PageFull/Page404';
import EventListenerClickDom from './componets/Include/EventListenerClickDom/EventListenerClickDom';
import OrdenPreCotizacion from "./componets/PageContent/OrdenPreCotizacion/OrdenPreCotizacion";
import {NewOrdenPreCotizacionIndex, OrdenPreCotizacionIndex1, OrdenPreCotizacionIndex2, OrdenPreCotizacionIndex3} from "./componets/PageContent/OrdenPreCotizacion";
import EspecialidadesIndex from './componets/PageContent/Cat/Especialidades';
import SemestreIndex from './componets/PageContent/Cat/Semestre';
import CicloEscolarIndex from './componets/PageContent/Cat/CicloEscolar';
import GeneracionIndex from './componets/PageContent/Cat/Generacion';
import ModalidadIndex from './componets/PageContent/Cat/Modalidad';
import AdministrativoIndex from './componets/PageContent/Administrativo';
import AlumnoIndex from './componets/PageContent/Alumno';
import MaestroIndex from './componets/PageContent/Maestro';

class AppRoutes extends Component {
	
	render() {
		return (
			<Fragment>
				<EventListenerClickDom/>
				<App>
					<Switch>
						<Route exact path="/login" component={LogInIndex}/>
						<Route exact path="/registro" component={RegistroIndex}/>
						<Route exact path="/registrodatospersonales" component={RegistroDatosPersonalesIndex}/>
						<Route exact path="/recuperar" component={RecuperarIndex}/>
						<Route exact path="/verificar" component={VerificarIndex}/>
						<Route exact path="/cambiarcontrasena" component={CambiarContrasenaIndex}/>
						<Route exact path="/catalogo" component={CatalogosIndex}/>
						<Route exact path="/genero" component={SexoIndex}/>
						<Route exact path="/grupo" component={GrupoIndex}/>
						<Route exact path="/comoteenteraste" component={ComoTeEnterasteIndex}/>
						<Route exact path="/formapago" component={FormaPagoIndex}/>
						<Route exact path="/metodopago" component={MetodoPagoIndex}/>
						<Route exact path="/giroempresa" component={GiroEmpresaIndex}/>
						<Route exact path="/tipousuario" component={TipoUsuarioIndex}/>
						<Route exact path="/tiporequerimiento" component={TipoRequerimientoIndex}/>
						<Route exact path="/menus" component={MenuSubMenuIndex}/>
						<Route exact path="/rolespermisos" component={RolesPermisosIndex}/>
						<Route exact path="/usuarioenviarreporte" component={UsuarioEnviarReporteIndex}/>
						<Route exact path="/categoria" component={CategoriaIndex}/>
						<Route exact path="/usuario" component={UsuariosIndex}/>
						<Route exact path="/proveedores" component={ProveedorIndex}/>
						<Route exact path="/producto" component={ProductoIndex}/>
						<Route exact path="/clientes" component={ClienteIndex}/>
						<Route exact path="/home" component={HomeIndex}/>
						<Route exact path="/tablero" component={TableroIndex}/>
						<Route exact path="/perfil" component={PerfilIndex}/>
						<Route exact path="/monitor" component={MonitorIndex}/>
						<Route exact path="/pagos" component={VentasNotaSencillaFacturaInternaIndex}/>
						<Route exact path="/listaprecotizacion" component={NewPreCotizacionIndex}/>
						<Route exact path="/precotizacion" component={OrdenPreCotizacionIndex1}/>
						<Route exact path="/precotizacion/:id_venta" component={OrdenPreCotizacionIndex2}/>
						<Route exact path="/precotizacion/:editar/:id_venta" component={OrdenPreCotizacionIndex3}/>
						<Route exact path="/cotizaciones" component={VentasCotizacionIndex}/>
						<Route exact path="/ordendepago" component={OrdenDeVentaIndex1}/>
						<Route exact path="/ordendepago/:id_venta" component={OrdenDeVentaIndex2}/>
						<Route exact path="/ordendepago/:editar/:id_venta" component={OrdenDeVentaIndex3}/>
						<Route exact path="/ordendepago/:tipo_vista" component={OrdenDeVentaIndex4}/>
						<Route exact path="/especialidades" component={EspecialidadesIndex}/>
						<Route exact path="/semestre" component={SemestreIndex}/>
						<Route exact path="/ciclo_escolar" component={CicloEscolarIndex}/>
						<Route exact path="/generacion" component={GeneracionIndex}/>
						<Route exact path="/modalidad" component={ModalidadIndex}/>
						<Route exact path="/administrativos" component={AdministrativoIndex}/>
						<Route exact path="/alumnos" component={AlumnoIndex}/>
						<Route exact path="/maestros" component={MaestroIndex}/>
						<Route exact path="/gastos" component={ComprasIndex}/>
						
						<Route exact path="/" component={LogInIndex}/>
						<Route component={Page404Index}/>
					</Switch>
				</App>
			</Fragment>
		);
	}
}

export default AppRoutes;
