import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const MaestroService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Maestro_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		
		let fecha_nacimiento = form.fecha_nacimiento ? DateFormat.FormatSql(form.fecha_nacimiento): null;
		let fecha_ingreso = form.fecha_ingreso ? DateFormat.FormatSql(form.fecha_ingreso): null;
		let fecha_egreso = form.fecha_egreso ? DateFormat.FormatSql(form.fecha_egreso): null;
		
		let params = {
			id_maestro: form.id_maestro || '',
			curp: form.curp || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			domicilio: form.domicilio || '',
			id_cat_sexo: form.id_cat_sexo || '',
			correo_electronico: form.correo_electronico || '',
			id_cat_estado: form.id_cat_estado || '',
			id_cat_municipio: form.id_cat_municipio || '',
			fecha_nacimiento: fecha_nacimiento,
			fecha_ingreso: fecha_ingreso,
			fecha_egreso: fecha_egreso,
			estatus_laboral: form.estatus_laboral || '',
			detalle_estatus_laboral: form.detalle_estatus_laboral || '',
			licenciatura: form.licenciatura || '',
			maestria: form.maestria || '',
			doctorado: form.doctorado || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Maestro_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		
		let fecha_nacimiento = form.fecha_nacimiento ? DateFormat.FormatSql(form.fecha_nacimiento): null;
		let fecha_ingreso = form.fecha_ingreso ? DateFormat.FormatSql(form.fecha_ingreso): null;
		let fecha_egreso = form.fecha_egreso ? DateFormat.FormatSql(form.fecha_egreso): null;
		
		let params = {
			id_maestro: form.id_maestro || '',
			curp: form.curp || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			domicilio: form.domicilio || '',
			id_cat_sexo: form.id_cat_sexo || '',
			correo_electronico: form.correo_electronico || '',
			id_cat_estado: form.id_cat_estado || '',
			id_cat_municipio: form.id_cat_municipio || '',
			fecha_nacimiento: fecha_nacimiento,
			fecha_ingreso: fecha_ingreso,
			fecha_egreso: fecha_egreso,
			estatus_laboral: form.estatus_laboral || '',
			detalle_estatus_laboral: form.detalle_estatus_laboral || '',
			licenciatura: form.licenciatura || '',
			maestria: form.maestria || '',
			doctorado: form.doctorado || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Maestro_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_maestro) => {let params = {
			id_maestro: id_maestro
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Maestro_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
