import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ComprasService} from "../../../../services/ComprasService/ComprasService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {
	AddOutlined,
	CancelOutlined,
	DeleteOutlined,
	EditOutlined,
	PlaylistAddOutlined,
	SaveOutlined
} from '@material-ui/icons';
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_3 from '../../../../assets/img/icons/carro-de-la-compra-3.svg'
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ModalProveedor from "../../Proveedor/Includes/ModalProveedor";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";

class ModalCompras extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			id_compra: props.id_compra,
			
			id_cat_tipo_comprobante: '',
			id_proveedor: '',
			quien_recibio: '',
			
			descripcion: '',
			fecha_compra: null,
			total: '',
			
			key: null,
			id_compra_producto: null,
			compra_producto: '',
			compra_descripcion: '',
			compra_cantidad: '',
			compra_precio_unitario: '',
			compra_total: '',
			iva_incluido: false,
			
			lista_compra_producto: [],
			
			lista_proveedor: [],
			lista_cat_tipo_comprobante: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		
		this.ListaProveedor();
		this.ListaTipoComprobante();
		
		return new Promise((resolve, reject) => {
			if (this.props.id_compra > 0) {
				ComprasService.Detalles(this.props.id_compra).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_compra = item.fecha_compra ? moment(item.fecha_compra) : null;
					this.setState({
						id_compra: item.id_compra,
						
						id_cat_tipo_comprobante: item.id_cat_tipo_comprobante || '',
						id_proveedor: item.id_proveedor || '',
						quien_recibio: item.quien_recibio || '',
						
						descripcion: item.descripcion || '',
						fecha_compra: fecha_compra,
						total: item.total || '',
						
						key: null,
						id_compra_producto: null,
						compra_producto: '',
						compra_descripcion: '',
						compra_cantidad: '',
						compra_precio_unitario: '',
						compra_total: '',
						iva_incluido: false,
						
						lista_compra_producto: item.compra_producto || [],
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					
					id_compra: null,
					
					id_cat_tipo_comprobante: '',
					id_proveedor: '',
					quien_recibio: '',
					
					descripcion: '',
					fecha_compra: null,
					total: '',
					
					key: null,
					id_compra_producto: null,
					compra_producto: '',
					compra_descripcion: '',
					compra_cantidad: '',
					compra_precio_unitario: '',
					compra_total: '',
					iva_incluido: false,
					
					lista_compra_producto: [],
				});
				resolve(true);
			}
		});
	};
	
	RefreshListProveedor = (id_proveedor) => {
		if (id_proveedor > 0) {
			this.setState({
				id_proveedor: id_proveedor
			})
		}
		this.ListaProveedor();
	};
	
	ListaProveedor = () => {
		CatService.ListProveedor().then(value => {
			this.setState({
				lista_proveedor: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_proveedor: []
			});
		});
	};
	
	ListaTipoComprobante = () => {
		CatService.ListTipoComprobante().then(value => {
			this.setState({
				lista_cat_tipo_comprobante: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_tipo_comprobante: []
			});
		});
	};
	
	add = () => {
		
		try {
			
			if (!this.state.compra_producto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Producto.'
				})
			}
			
			if (!(this.state.compra_cantidad > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!(this.state.compra_precio_unitario > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Precio Unitario.'
				})
			}
			
			if (!(this.state.compra_total > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Total.'
				})
			}
			
			let lista_compra_producto = FieldsJs.Copy(this.state.lista_compra_producto || []);
			
			if (this.is_edited_product()) {
				for (let i = 0; i < lista_compra_producto.length; i++) {
					let item = lista_compra_producto[i];
					if (this.state.key === i) {
						item.compra_producto = this.state.compra_producto || '';
						item.compra_descripcion = this.state.compra_descripcion || '';
						item.compra_cantidad = this.state.compra_cantidad || 0;
						item.compra_precio_unitario = this.state.compra_precio_unitario || 0;
						item.compra_total = this.state.compra_total || 0;
						item.iva_incluido = this.state.iva_incluido ? 1 : 0;
					}
				}
			} else {
				let item;
				item = {
					id_compra_producto: null,
					id_compra: this.state.id_compra || null,
					compra_producto: this.state.compra_producto || '',
					compra_descripcion: this.state.compra_descripcion || '',
					compra_cantidad: this.state.compra_cantidad || 0,
					compra_precio_unitario: this.state.compra_precio_unitario || 0,
					compra_total: this.state.compra_total || 0,
					iva_incluido: this.state.iva_incluido ? 1 : 0,
				};
				lista_compra_producto.push(item);
			}
			
			this.setState({
				key: null,
				id_compra_producto: '',
				compra_producto: '',
				compra_descripcion: '',
				compra_cantidad: '',
				compra_precio_unitario: '',
				compra_total: '',
				iva_incluido: false,
				lista_compra_producto: lista_compra_producto
			});
			
			this.calcularTotalCompra();
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	save = () => {
		try {
			
			if (!this.state.descripcion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descripción.'
				})
			}
			
			if (!this.state.fecha_compra) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha de compra.'
				})
			}
			
			if (!this.state.total) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe.'
				})
			}
			
			try {
				ComprasService.Guardar(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	edit = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_compra_producto: value.id_compra_producto,
				compra_producto: value.compra_producto,
				compra_descripcion: value.compra_descripcion,
				compra_cantidad: value.compra_cantidad,
				compra_precio_unitario: value.compra_precio_unitario,
				compra_total: value.compra_total,
				iva_incluido: (value.iva_incluido === 1),
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_compra_producto: '',
			compra_producto: '',
			compra_descripcion: '',
			compra_cantidad: '',
			compra_precio_unitario: '',
			compra_total: '',
			iva_incluido: false,
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar esta este producto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_compra_producto = FieldsJs.Copy(this.state.lista_compra_producto);
					
					lista_compra_producto.splice(key, 1);
					
					this.setState({
						lista_compra_producto: lista_compra_producto
					});
					
					this.calcularTotalCompra();
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	calcularTotalProducto = () => {
		setTimeout(() => {
			let compra_cantidad = this.state.compra_cantidad;
			let compra_precio_unitario = this.state.compra_precio_unitario;
			let compra_total = 0;
			
			if (compra_cantidad > 0 && compra_precio_unitario > 0) {
				compra_total = (compra_cantidad * compra_precio_unitario);
			}
			
			this.setState({
				compra_total: compra_total
			});
		});
	};
	
	calcularPrecioUnitarioProducto = () => {
		setTimeout(() => {
			let compra_cantidad = this.state.compra_cantidad || 1;
			let compra_precio_unitario = 0;
			let compra_total = this.state.compra_total;
			
			if (compra_cantidad > 0 && compra_total > 0) {
				compra_precio_unitario = (compra_total / compra_cantidad);
			}
			
			this.setState({
				compra_cantidad: compra_cantidad,
				compra_precio_unitario: compra_precio_unitario
			});
		});
	};
	
	calcularTotalCompra = () => {
		setTimeout(() => {
			let lista_compra_producto = this.state.lista_compra_producto || [];
			let total = 0;
			for (let i = 0; i < lista_compra_producto.length; i++) {
				let item = lista_compra_producto[i];
				if (Number(item.compra_total) > 0) {
					total += this.calcularTotalConSinIVA(item);
				}
			}
			this.setState({
				total: total
			});
		});
	};
	
	calcularSubTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1) {
			return value.compra_precio_unitario / 1.16;
		} else {
			return value.compra_precio_unitario;
		}
	};
	
	calcularIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1) {
			return value.compra_precio_unitario - (value.compra_precio_unitario / 1.16);
		} else {
			return value.compra_precio_unitario * 0.16;
		}
	};
	
	calcularTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1) {
			return value.compra_precio_unitario;
		} else {
			return value.compra_precio_unitario * 1.16;
		}
	};
	
	calcularSubTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1) {
			return value.compra_total / 1.16;
		} else {
			return value.compra_total;
		}
	};
	
	calcularIVA = (value) => {
		if (value.iva_incluido === 1) {
			return value.compra_total - (value.compra_total / 1.16);
		} else {
			return value.compra_total * 0.16;
		}
	};
	
	calcularTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1) {
			return value.compra_total;
		} else {
			return value.compra_total * 1.16;
		}
	};
	
	calcularDesgoseTotales = () => {
		let desglose = {
			subtotal: 0,
			iva: 0,
			total: 0,
		};
		let lista_compra_producto = this.state.lista_compra_producto;
		for (let i = 0; i < lista_compra_producto.length; i++) {
			let item = lista_compra_producto[i];
			desglose.subtotal += this.calcularSubTotalConSinIVA(item);
			desglose.iva += this.calcularIVA(item);
			desglose.total += this.calcularTotalConSinIVA(item);
		}
		return desglose;
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	render() {
		
		const is_root = this.is_root();
		
		const desglose = this.calcularDesgoseTotales();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Compra / Gastos
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<ModalProveedor
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Agregar nuevo proveedor"
															children={(
																<Fab
																	color="secondary"
																	size="small"
																	aria-label="Agregar nuevo proveedor"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_proveedor={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshListProveedor}
													showSnackBars={this.props.showSnackBars}
												/>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_proveedor"
											label="Proveedor"
											value={this.state.id_proveedor}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_proveedor.map(option => (
												<option key={option.id_proveedor} value={option.id_proveedor}>
													{option.nombre_comercial}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									margin="none"
									name="quien_recibio"
									label="Quien recibio"
									type="text"
									fullWidth
									value={this.state.quien_recibio}
									onChange={this.handleChange}
									required={true}
									disabled={this.is_view()}
								/>
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									disabled={this.is_view()}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_tipo_comprobante"
									label="Tipo de comprobante"
									value={this.state.id_cat_tipo_comprobante}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_cat_tipo_comprobante.map(option => (
										<option key={option.id_cat_tipo_comprobante}
										        value={option.id_cat_tipo_comprobante}>
											{option.tipo_comprobante}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a" fullWidth required clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de compra',
									}}
									label="Fecha de compra"
									value={this.state.fecha_compra}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_compra');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									
									disabled={this.is_view()}
								/>
							</Grid>
							
							<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
								<TextField
									rows={4}
									multiline
									margin="none"
									name="descripcion"
									label="Descripción"
									type="text"
									fullWidth
									value={this.state.descripcion}
									onChange={this.handleChange}
									required={true}
									disabled={this.is_view()}
								/>
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"} alignContent={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"center"}>
										<Typography component={'h2'} className={'margin-0 padding-0 px-14'}>
											Monto total:
										</Typography>
										<Typography component={'h2'} className={'margin-0 padding-0 px-25'}>
											${FieldsJs.Currency(this.state.total)}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							
							{!this.is_view() ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
												<TextField
													margin="none"
													name="compra_producto"
													label="Producto"
													type="text"
													fullWidth
													value={this.state.compra_producto}
													onChange={this.handleChange}
													required={true}
												/>
											</Grid>
											<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
												<TextField
													margin="none"
													name="compra_descripcion"
													label="Descripción"
													type="text"
													fullWidth
													value={this.state.compra_descripcion}
													onChange={this.handleChange}
													required={false}
												/>
											</Grid>
											<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
												<Grid container spacing={2} alignItems={"flex-end"}
												      alignContent={"flex-end"}>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<TextField
															margin="none"
															name="compra_cantidad"
															label="Cantidad"
															type="text"
															fullWidth
															value={this.state.compra_cantidad}
															onChange={(e) => {
																this.handleChange(e);
																this.calcularTotalProducto();
															}}
															onKeyPress={EnteroSolo}
															inputProps={{maxLength: 5}}
															required={true}
														/>
													</Grid>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<TextField
															margin="none"
															name="compra_precio_unitario"
															label="Precio Unitario"
															type="text"
															fullWidth
															value={this.state.compra_precio_unitario}
															onChange={(e) => {
																this.handleChange(e);
																this.calcularTotalProducto();
															}}
															onKeyPress={EnteroSolo}
															inputProps={{maxLength: 50}}
															required={true}
														/>
													</Grid>
													
													<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
														<TextField
															margin="none"
															name="compra_total"
															label="Importe"
															type="text"
															fullWidth
															value={this.state.compra_total}
															onChange={(e) => {
																this.handleChange(e);
																this.calcularPrecioUnitarioProducto();
															}}
															onKeyPress={EnteroSolo}
															inputProps={{maxLength: 50}}
															required={true}
														/>
													</Grid>
													
													<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
														<FormGroup row>
															<FormControlLabel
																control={
																	<Checkbox
																		type="checkbox"
																		name='iva_incluido'
																		checked={this.state.iva_incluido}
																		onChange={this.handleChange}
																		value="iva_incluido"
																		color="primary"
																		disabled={this.props.tipo === 'view'}
																	/>
																}
																label={'iva incluido'}
															/>
														</FormGroup>
													</Grid>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={"right"}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Cancelar edición"
															children={(
																<span>
																<Fab size="small" color="secondary"
																     aria-label="Cancelar edición"
																     onClick={() => this.edit_cancel()}
																     style={{marginLeft: "15px"}}
																     disabled={!this.is_edited_product()}>
																	<CancelOutlined/>
																</Fab>
															</span>
															)}
														/>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title={this.is_edited_product() ? "Actualizar" : "Agregar"}
															children={(
																<Fab size="small" color="secondary"
																     aria-label={this.is_edited_product() ? "Actualizar" : "Agregar"}
																     onClick={() => this.add()}
																     style={{marginLeft: "15px"}}>
																	{this.is_edited_product() ? (
																		<SaveOutlined/>
																	) : (
																		<AddOutlined/>
																	)}
																</Fab>
															)}
														/>
													</Grid>
												
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Fragment>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<table className={'desing-mark'}>
									<thead>
									<tr>
										<th>Producto</th>
										<th>Descripción</th>
										{is_root ? (
											<th align={"center"} style={{color: '#00a8ff'}}>IVA incluido</th>
										) : null}
										<th align={"center"}>Cantidad</th>
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Importe P. U.</th>
										) : null}
										<th align={"right"}>
											{is_root ? 'Subtotal P. U.' : 'Precio Unitario'}
										</th>
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>IVA P. U.</th>
										) : null}
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Precio Unitario</th>
										) : null}
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Importe Total</th>
										) : null}
										<th align={"right"}>
											{is_root ? 'Subtotal' : 'Total'}
										</th>
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>IVA</th>
										) : null}
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Importe</th>
										) : null}
										{!this.is_view() ? (
											<th align={'right'} width={'110px'}>Acciones</th>
										) : null}
									</tr>
									</thead>
									<tbody>
									{this.state.lista_compra_producto.map((value, index) => (
										<tr key={index}>
											<td>{value.compra_producto}</td>
											<td>{value.compra_descripcion}</td>
											{is_root ? (
												<td align={"center"}>
													{value.iva_incluido === 1 ? (
														<div style={{
															width: "15px",
															color: "white",
															borderRadius: "3px",
															padding: "1px 7px",
															background: "#3F51B5",
															display: "inline-grid",
															fontSize: '12px',
														}}>Si</div>
													) : (
														<div style={{
															width: "15px",
															color: "white",
															borderRadius: "3px",
															padding: "1px 7px",
															background: "#FF5722",
															display: "inline-grid",
															fontSize: '12px',
														}}>No</div>
													)}
												</td>
											) : null}
											<td align={"center"}>
												{value.compra_cantidad}
											</td>
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													${FieldsJs.Currency(value.compra_precio_unitario)}
												</td>
											) : null}
											<td align={"right"}>
												<b>
													${FieldsJs.Currency(this.calcularSubTotalConSinIVAPrecioUnitario(value))}
												</b>
											</td>
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularIVAPrecioUnitario(value))}
													</b>
												</td>
											) : null}
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularTotalConSinIVAPrecioUnitario(value))}
													</b>
												</td>
											) : null}
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													${FieldsJs.Currency(value.compra_total)}
												</td>
											) : null}
											<td align={"right"}>
												<b>
													${FieldsJs.Currency(this.calcularSubTotalConSinIVA(value))}
												</b>
											</td>
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularIVA(value))}
													</b>
												</td>
											) : null}
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularTotalConSinIVA(value))}
													</b>
												</td>
											) : null}
											{!this.is_view() ? (
												<td align={'right'}>
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Eliminar"
														children={(
															<Fab
																size="small"
																aria-label="Eliminar"
																onClick={() => this.delete(value, index)}
																style={{
																	marginLeft: "10px",
																	backgroundColor: "transparent",
																	color: "#808080",
																	boxShadow: "none",
																}}
																children={(
																	<DeleteOutlined/>
																)}
															/>
														)}
													/>
													{this.state.key === index ? (
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Producto en edición"
															children={(
																<Fab
																	size="small"
																	aria-label="Editar"
																	onClick={() => this.edit(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "#f50057",
																		color: "white",
																	}}
																	children={(
																		<EditOutlined/>
																	)}
																/>
															)}
														/>
													) : (
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Editr producto"
															children={(
																<Fab
																	size="small"
																	aria-label="Editar"
																	onClick={() => this.edit(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "transparent",
																		color: "#808080",
																		boxShadow: "none",
																	}}
																	children={(
																		<EditOutlined/>
																	)}
																/>
															)}
														/>
													)}
												</td>
											) : null}
										</tr>
									))}
									{!(this.state.lista_compra_producto.length > 0) ? (
										<tr>
											<td colSpan={!this.is_view() ? (is_root ? 13 : 6) : (is_root ? 12 : 5)}
											    align={'center'}>
												<VistaVacia
													numero={null}
													mensaje={'Ningún producto agregado.'}
													image={carrito_de_compra_3}
													padding={'20px'}
													paddingText={'20px'}
													height={'80px'}
													width={'80px'}
												/>
											</td>
										</tr>
									) : null}
									<tr>
										<td colSpan={(is_root ? 10 : 3)}/>
										<td align={"right"}>Subtotal</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.subtotal)}</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={(is_root ? 10 : 3)}/>
										<td align={"right"}>IVA</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.iva)}</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={(is_root ? 10 : 3)}/>
										<td align={"right"}>Total</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.total)}</td>
										<td/>
									</tr>
									</tbody>
								</table>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Agregar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCompras.propTypes = {
	id_compra: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCompras;
