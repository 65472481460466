import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {ProductoService} from '../../../../services/ProductoService/ProductoService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import Categoria from "../../Cat/Categoria/Categoria";
import {CatService} from "../../../../services/_Cat/CatService/CatService";

class ModalProducto extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_producto: props.id_producto || 0,
			id_cat_categoria: '',
			clave: '',
			producto: '',
			descripcion: '',
			cantidad: '',
			precio_unitario: '',
			codigo_descuento: '',
			imagen_base64Tipo: '',
			imagen_base64: '',
			imagen_archivo: '',
			imagen_formato: '',
			activo: true,
			
			
			lista_categoria: [],
		};
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			CatService.ListCategoria(true).then(response => {
				this.setState({
					lista_categoria: response.data,
				});
				resolve(true);
			}).catch(error => {
				this.props.showSnackBars('error', error.mensaje);
				this.setState({
					lista_categoria: [],
				});
				reject(false);
			});
		});
	};
	
	save = () => {
		if (this.state.id_producto > 0) {
			ProductoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			ProductoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_producto: '',
			id_cat_categoria: '',
			clave: '',
			producto: '',
			descripcion: '',
			cantidad: '',
			precio_unitario: '',
			codigo_descuento: '',
			imagen_base64Tipo: '',
			imagen_base64: '',
			imagen_archivo: '',
			imagen_formato: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {id_producto, item} = this.props;
		console.log(this.props);
		this.setState({
			id_producto: id_producto || '',
			id_cat_categoria: item.id_cat_categoria || '',
			clave: item.clave || '',
			producto: item.producto || '',
			descripcion: item.descripcion || '',
			cantidad: item.cantidad || '',
			precio_unitario: item.precio_unitario || '',
			codigo_descuento: item.codigo_descuento || '',
			imagen_base64Tipo: item.imagen_base64Tipo || '',
			imagen_base64: item.imagen_base64 || '',
			imagen_archivo: item.imagen_archivo || '',
			imagen_formato: item.imagen_formato || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {id_producto, item} = this.props;
		console.log(this.props);
		this.setState({
			id_producto: id_producto || '',
			id_cat_categoria: item.id_cat_categoria || '',
			clave: item.clave || '',
			producto: item.producto || '',
			descripcion: item.descripcion || '',
			cantidad: item.cantidad || '',
			precio_unitario: item.precio_unitario || '',
			codigo_descuento: item.codigo_descuento || '',
			imagen_base64Tipo: item.imagen_base64Tipo || '',
			imagen_base64: item.imagen_base64 || '',
			imagen_archivo: item.imagen_archivo || '',
			imagen_formato: item.imagen_formato || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_producto > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	render() {
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Producto</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									helperText="Requerido"
									name="id_cat_categoria"
									label="Categoría"
									value={this.state.id_cat_categoria}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_categoria.map(option => (
										<option key={option.id_cat_categoria} value={option.id_cat_categoria}>
											{option.categoria}
										</option>
									))}
								</TextField>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									required
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 10}}
									
									name="clave"
									label="Clave"
									defaultValue={this.state.clave}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									required
									
									name="producto"
									label="Producto"
									defaultValue={this.state.producto}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									
									name="descripcion"
									label="Descripción"
									defaultValue={this.state.descripcion}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 10}}
									
									name="cantidad"
									label="Cantidad"
									defaultValue={this.state.cantidad}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									
									name="precio_unitario"
									label="Precio unitario"
									defaultValue={this.state.precio_unitario}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									
									name="codigo_descuento"
									label="Código descuento"
									defaultValue={this.state.codigo_descuento}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BoxSelectFile
									FormatAccepted={["image/png", "image/jpeg"]}
									MegaByte={5}
									// button={(
									// 	<AddOutlined/>
									// )}
									label={(
										<b className={'vertical-inline v-center text-blue'}>Imagen Producto</b>
									)}
									style={{
										color: 'black',
										height: '115px'
									}}
									item={{
										base64: this.state.imagen_base64,
										base64Tipo: this.state.imagen_base64Tipo,
										archivo: this.state.imagen_archivo,
										formato: this.state.imagen_formato,
									}}
									onChange={(r) => {
										this.handleChange({
											target: {
												name: 'imagen_base64',
												value: r.base64
											}
										});
										this.handleChange({
											target: {
												name: 'imagen_base64Tipo',
												value: r.base64Tipo
											}
										});
										this.handleChange({
											target: {
												name: 'imagen_archivo',
												value: r.archivo
											}
										});
										this.handleChange({
											target: {
												name: 'imagen_formato',
												value: r.formato
											}
										});
									}}
									showSnackBars={this.props.showSnackBars}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.close()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_producto > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalProducto.propTypes = {
	id_producto: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalProducto;
