import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const AlumnoService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				nombre: filtro.nombre || null,
				curp: filtro.curp || null,
				correo_electronico: filtro.correo_electronico || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Alumno_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_nacimiento = form.fecha_nacimiento ? DateFormat.FormatSql(form.fecha_nacimiento): null;
		
		let params = {
			id_alumno: form.id_alumno || '',
			curp: form.curp || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			domicilio: form.domicilio || '',
			id_cat_sexo: form.id_cat_sexo || '',
			correo_electronico: form.correo_electronico || '',
			id_cat_estado: form.id_cat_estado || '',
			id_cat_municipio: form.id_cat_municipio || '',
			fecha_nacimiento: fecha_nacimiento,
			escuela_procedencia: form.escuela_procedencia || '',
			direccion_escuela_procedencia: form.direccion_escuela_procedencia || '',
			clave: form.clave || '',
			promedio: form.promedio || '',
			matricula: form.matricula || '',
			referencia_bancaria: form.referencia_bancaria || '',
			id_tutor: form.id_tutor || '',
			nombre_tutor: form.nombre_tutor || '',
			apellido_paterno_tutor: form.apellido_paterno_tutor || '',
			apellido_materno_tutor: form.apellido_materno_tutor || '',
			telefono_tutor: form.telefono_tutor || '',
			domicilio_tutor: form.domicilio_tutor || '',
			lugar_trabajo_tutor: form.lugar_trabajo_tutor || '',
			domicilio_trabajo_tutor: form.domicilio_trabajo_tutor || '',
			telefono_emergencia_tutor: form.telefono_emergencia_tutor || '',
			domicilio_emergencia_tutor: form.domicilio_emergencia_tutor || '',
			id_cat_semestre: form.id_cat_semestre || '',
			id_cat_especialidad: form.id_cat_especialidad || '',
			id_cat_ciclo_escolar: form.id_cat_ciclo_escolar || '',
			id_cat_generacion: form.id_cat_generacion || '',
			id_cat_modalidad: form.id_cat_modalidad || '',
			archivos: form.archivos || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Alumno_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_nacimiento = form.fecha_nacimiento ? DateFormat.FormatSql(form.fecha_nacimiento): null;
		
		let params = {
			id_alumno: form.id_alumno || '',
			curp: form.curp || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			domicilio: form.domicilio || '',
			id_cat_sexo: form.id_cat_sexo || '',
			correo_electronico: form.correo_electronico || '',
			id_cat_estado: form.id_cat_estado || '',
			id_cat_municipio: form.id_cat_municipio || '',
			fecha_nacimiento: fecha_nacimiento,
			escuela_procedencia: form.escuela_procedencia || '',
			direccion_escuela_procedencia: form.direccion_escuela_procedencia || '',
			clave: form.clave || '',
			promedio: form.promedio || '',
			matricula: form.matricula || '',
			referencia_bancaria: form.referencia_bancaria || '',
			id_tutor: form.id_tutor || '',
			nombre_tutor: form.nombre_tutor || '',
			apellido_paterno_tutor: form.apellido_paterno_tutor || '',
			apellido_materno_tutor: form.apellido_materno_tutor || '',
			telefono_tutor: form.telefono_tutor || '',
			domicilio_tutor: form.domicilio_tutor || '',
			lugar_trabajo_tutor: form.lugar_trabajo_tutor || '',
			domicilio_trabajo_tutor: form.domicilio_trabajo_tutor || '',
			telefono_emergencia_tutor: form.telefono_emergencia_tutor || '',
			domicilio_emergencia_tutor: form.domicilio_emergencia_tutor || '',
			id_cat_semestre: form.id_cat_semestre || '',
			id_cat_especialidad: form.id_cat_especialidad || '',
			id_cat_ciclo_escolar: form.id_cat_ciclo_escolar || '',
			id_cat_generacion: form.id_cat_generacion || '',
			id_cat_modalidad: form.id_cat_modalidad || '',
			archivos: form.archivos || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Alumno_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_alumno) => {let params = {
			id_alumno: id_alumno
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Alumno_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
