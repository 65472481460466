import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const CatService = {
	Cfg: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/config').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEstado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estado').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMunicipio: (id_estado) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/municipio/' + id_estado).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSexo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/sexo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoUsuario: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGiroEmpresa: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/giro_empresa').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioDisenador: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_disenador').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGrupo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/grupo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListComoTeEnteraste: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/como_te_enteraste').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioTipoReporte: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_tipo_reporte').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoReporte: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_reporte').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCategoria: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/categoria', {}, {spinner: spinner || false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoComprobante: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_comprobante').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProveedor: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/proveedor').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListAreaTrabajo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/area_trabajo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListArchivoIntegracionExpediente: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/alumno_documento').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCurso: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/curso').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoPlaza: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_plaza').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSemestre: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/semestre').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCicloEscolar: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/ciclo_escolar').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEspecialidad: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/especialidad').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListModalidad: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/modalidad').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGeneracion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/generacion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	
};
