import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {AlumnoService} from "../../../../services/_Sis/AlumnoService/AlumnoService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	DeviceHubOutlined,
	AlternateEmailOutlined,
	HdrWeakOutlined,
	HdrStrongOutlined,
	PhoneOutlined,
	CalendarTodayOutlined,
	TurnedInNotOutlined,
	Home,
	Business,
	LocalHospital
} from "@material-ui/icons";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import SeccionExpedientes from "./SeccionExpedientes";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {CONFIG} from "../../../../settings/Config/Config";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {Dropbox} from 'dropbox';


class ModalAlumno extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo,
			id_alumno: (props.item || {}).id_alumno,
			id_tutor: '',
			curp: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			domicilio: '',
			id_cat_sexo: '',
			correo_electronico: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			fecha_nacimiento: null,
			escuela_procedencia: '',
			direccion_escuela_procedencia: '',
			clave: '',
			promedio: '',
			matricula: '',
			referencia_bancaria: '',
			nombre_tutor: '',
			apellido_paterno_tutor: '',
			apellido_materno_tutor: '',
			telefono_tutor: '',
			domicilio_tutor: '',
			lugar_trabajo_tutor: '',
			domicilio_trabajo_tutor: '',
			telefono_emergencia_tutor: '',
			domicilio_emergencia_tutor: '',
			id_cat_semestre: '',
			id_cat_especialidad: '',
			id_cat_ciclo_escolar: '',
			id_cat_generacion: '',
			id_cat_modalidad: '',
			
			list_cat_modalidad: [],
			list_cat_sexo: [],
			list_cat_estado: [],
			list_cat_municipio: [],
			list_cat_semestre: [],
			list_cat_especialidad: [],
			list_cat_ciclo_escolar: [],
			list_cat_generacion: [],
			listar_archivo_integracion_expediente: [],
			archivos: []
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	guardarExpedienteAcreditadoDbx = () => {
		let ftar = this.state.listar_archivo_integracion_expediente;
		console.log("1 FTAR::: ", ftar);
		
		let files;
		let archivos = [];
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i].archivo) === true) {
					band++;
				}
			}
			
			if (band === 0) {
				resolve(archivos);
			}
			
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i].archivo) === true) {
					console.log("2 FTAR::: ", ftar);
					files = ftar[i].file;
					
					if (FieldsJs.Field(files) === true) {
						DropboxApi.Upload('/Alumnos_Expedientes/' + this.state.curp + '/' + ftar[i].titulo + '.' + ftar[i].formato, files).then(function (response) {
							ftar[i].id_archivo = response.id;
							
							archivos.push(
								{
									"cve_doc": ftar[i].cve_doc,
									"titulo": ftar[i].titulo || '',
									"original": ftar[i].original || 0,
									"copia": ftar[i].copia || 0,
									"descripcion": ftar[i].descripcion || '',
									"id_archivo": ftar[i].id_archivo || '',
									"archivo": response.path_display || ''
								}
							);
							
							band2++;
							
							console.log("DROPBOX UPLOAD: ", response, band, band2);
							
							if (band === band2) {
								resolve(archivos);
							}
						}).catch(function (error) {
							reject(error);
						});
					}
				}
				
			}
		});
	};
	
	viewDbx = (item) => {
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let path = item.path;
		console.log("ITEM::: ", item, path);
		
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
	};
	
	save = () => {
		try {
			
			if (!FieldsJs.Field(this.state.curp)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo CURP es requerido.",
				});
			}
			
			
			showSpinner('spinner');
			
			let ftar = this.state.listar_archivo_integracion_expediente;
			
			if (ftar.length > 0) {
				if (this.state.id_alumno > 0 || FieldsJs.Field(this.state.id_alumno)) {
					
					let guardar = new Promise((resolve, reject) => {
						this.guardarExpedienteAcreditadoDbx().then(response => {
							console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
							
							let arc = FieldsJs.Copy(this.state.archivos);
							for (let i = 0; i < response.length; i++) {
								arc.push(response[i]);
							}
							
							this.setState({
								archivos: arc
							});
							resolve(response);
							console.log("DROPBOX UPLOAD: ", response);
							
						}).catch(error => {
							hideSpinner('spinner', 500);
							reject(error);
						})
					});
					
					guardar.then(response => {
						AlumnoService.Modificar(this.state).then((response) => {
							this.close();
							this.props.RefreshList();
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
					})
					
				} else {
					let guardar = new Promise((resolve, reject) => {
						this.guardarExpedienteAcreditadoDbx().then(response => {
							console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
							this.setState({
								archivos: response
							});
							resolve(response);
							console.log("DROPBOX UPLOAD: ", response);
							
						}).catch(error => {
							hideSpinner('spinner', 500);
							reject(error);
						})
					});
					
					guardar.then(response => {
						AlumnoService.Agregar(this.state).then((response) => {
							this.close();
							this.props.RefreshList(response.data);
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
					})
				}
			} else {
				AlumnoService.Agregar(this.state).then((response) => {
					this.close();
					this.props.RefreshList(response.data);
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
			
			
		} catch (e) {
			if (!!e.popup_alert === true) {
				/*PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, e.mensaje).then((r) => {
					if (r.button === 'Aceptar') {
						this.guardarPromotor('si')
					}
				});*/
				this.props.showSnackBars('error', e.mensaje);
			} else {
				this.props.showSnackBars('error', e.mensaje);
			}
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_alumno: '',
			curp: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			domicilio: '',
			id_cat_sexo: '',
			correo_electronico: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			fecha_nacimiento: null,
			escuela_procedencia: '',
			direccion_escuela_procedencia: '',
			clave: '',
			promedio: '',
			matricula: '',
			referencia_bancaria: '',
			nombre_tutor: '',
			apellido_paterno_tutor: '',
			apellido_materno_tutor: '',
			telefono_tutor: '',
			domicilio_tutor: '',
			lugar_trabajo_tutor: '',
			domicilio_trabajo_tutor: '',
			telefono_emergencia_tutor: '',
			domicilio_emergencia_tutor: '',
			id_cat_semestre: '',
			id_cat_especialidad: '',
			id_cat_ciclo_escolar: '',
			id_cat_generacion: '',
			id_cat_modalidad: '',
			archivos: [],
			activo: true,
		});
		this.ListSexo();
		this.ListSemestre();
		this.ListGeneracion();
		this.ListCicloEscolar();
		this.ListEspecialidad();
		this.ListModalidad();
		this.ListEstado();
		this.ListarArchivoIntegracionExpediente();
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		const tutor = item.tutor || null;
		console.log(this.props);
		this.setState({
			id_alumno: item.id_alumno || '',
			curp: item.curp || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			telefono: item.telefono || '',
			domicilio: item.domicilio || '',
			correo_electronico: item.correo_electronico || '',
			fecha_nacimiento: item.fecha_nacimiento || null,
			escuela_procedencia: item.escuela_procedencia || '',
			direccion_escuela_procedencia: item.direccion_escuela_procedencia || '',
			clave: item.clave || '',
			promedio: item.promedio || '',
			matricula: item.matricula || '',
			referencia_bancaria: item.referencia_bancaria || '',
			archivos: item.archivos || [],
			listar_archivo_integracion_expediente: item.listar_archivo_integracion_expediente || [],
			activo: (item.activo === 1),
		});
		
		if (FieldsJs.Field(tutor)) {
			this.setState({
				id_tutor: tutor.id_tutor || '',
				nombre_tutor: tutor.nombre || '',
				apellido_paterno_tutor: tutor.apellido_paterno || '',
				apellido_materno_tutor: tutor.apellido_materno || '',
				telefono_tutor: tutor.telefono || '',
				domicilio_tutor: tutor.domicilio || '',
				lugar_trabajo_tutor: tutor.lugar_trabajo || '',
				domicilio_trabajo_tutor: tutor.domicilio_trabajo || '',
				telefono_emergencia_tutor: tutor.telefono_emergencia || '',
				domicilio_emergencia_tutor: tutor.domicilio_emergencia || '',
			});
		}
		this.ListSexo().then((response) => {
			this.setState({
				id_cat_sexo: item.id_cat_sexo || ''
			});
		});
		this.ListEstado().then((response) => {
			this.setState({
				id_cat_estado: item.id_cat_estado || ''
			});
		});
		this.ListMunicipio(item.id_cat_estado, 0).then((response) => {
			this.setState({
				id_cat_municipio: item.id_cat_municipio || ''
			});
		});
		this.ListSemestre().then((response) => {
			this.setState({
				id_cat_semestre: item.id_cat_semestre || '',
			});
		});
		this.ListGeneracion().then((response) => {
			this.setState({
				id_cat_generacion: item.id_cat_generacion || '',
			});
		});
		this.ListCicloEscolar().then((response) => {
			this.setState({
				id_cat_ciclo_escolar: item.id_cat_ciclo_escolar || '',
			});
		});
		this.ListEspecialidad().then((response) => {
			this.setState({
				id_cat_especialidad: item.id_cat_especialidad || '',
			});
		});
		this.ListModalidad().then((response) => {
			this.setState({
				id_cat_modalidad: item.id_cat_modalidad || '',
			});
		});
		this.ListarArchivoIntegracionExpediente().then((response) => {
			if (item.archivos.length) {
				for (let i = 0; i < item.archivos.length; i++) {
					for (let j = 0; j < this.state.listar_archivo_integracion_expediente.length; j++) {
						if (Number(this.state.archivos[i].cve_doc) === Number(this.state.listar_archivo_integracion_expediente[j].cve_doc)) {
							this.state.listar_archivo_integracion_expediente[j].formato = item.archivos[i].formato;
							this.state.listar_archivo_integracion_expediente[j].titulo = item.archivos[i].titulo;
							this.state.listar_archivo_integracion_expediente[j].original = item.archivos[i].original;
							this.state.listar_archivo_integracion_expediente[j].copia = item.archivos[i].copia;
							this.state.listar_archivo_integracion_expediente[j].id_archivo = item.archivos[i].id_archivo;
							this.state.listar_archivo_integracion_expediente[j].path = item.archivos[i].archivo;
							this.state.listar_archivo_integracion_expediente[j].descripcion = item.archivos[i].descripcion;
						}
					}
				}
			}
			
			console.log("this.state.listar_archivo_integracion_expediente::::::", this.state.listar_archivo_integracion_expediente);
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		const tutor = item.tutor || null;
		console.log(this.props);
		this.setState({
			id_alumno: item.id_alumno || '',
			curp: item.curp || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			telefono: item.telefono || '',
			domicilio: item.domicilio || '',
			correo_electronico: item.correo_electronico || '',
			fecha_nacimiento: item.fecha_nacimiento || null,
			escuela_procedencia: item.escuela_procedencia || '',
			direccion_escuela_procedencia: item.direccion_escuela_procedencia || '',
			clave: item.clave || '',
			promedio: item.promedio || '',
			matricula: item.matricula || '',
			referencia_bancaria: item.referencia_bancaria || '',
			archivos: item.archivos || [],
			listar_archivo_integracion_expediente: item.listar_archivo_integracion_expediente || [],
			activo: (item.activo === 1),
		});
		if (FieldsJs.Field(tutor)) {
			this.setState({
				id_tutor: tutor.id_tutor || '',
				nombre_tutor: tutor.nombre || '',
				apellido_paterno_tutor: tutor.apellido_paterno || '',
				apellido_materno_tutor: tutor.apellido_materno || '',
				telefono_tutor: tutor.telefono || '',
				domicilio_tutor: tutor.domicilio || '',
				lugar_trabajo_tutor: tutor.lugar_trabajo || '',
				domicilio_trabajo_tutor: tutor.domicilio_trabajo || '',
				telefono_emergencia_tutor: tutor.telefono_emergencia || '',
				domicilio_emergencia_tutor: tutor.domicilio_emergencia || '',
			});
		}
		this.ListSexo().then((response) => {
			this.setState({
				id_cat_sexo: item.id_cat_sexo || ''
			});
		});
		this.ListEstado().then((response) => {
			this.setState({
				id_cat_estado: item.id_cat_estado || ''
			});
		});
		this.ListMunicipio(item.id_cat_estado, 0).then((response) => {
			this.setState({
				id_cat_municipio: item.id_cat_municipio || ''
			});
		});
		this.ListSemestre().then((response) => {
			this.setState({
				id_cat_semestre: item.id_cat_semestre || '',
			});
		});
		this.ListGeneracion().then((response) => {
			this.setState({
				id_cat_generacion: item.id_cat_generacion || '',
			});
		});
		this.ListCicloEscolar().then((response) => {
			this.setState({
				id_cat_ciclo_escolar: item.id_cat_ciclo_escolar || '',
			});
		});
		this.ListEspecialidad().then((response) => {
			this.setState({
				id_cat_especialidad: item.id_cat_especialidad || '',
			});
		});
		this.ListModalidad().then((response) => {
			this.setState({
				id_cat_modalidad: item.id_cat_modalidad || '',
			});
		});
		this.ListarArchivoIntegracionExpediente().then((response) => {
			if (item.archivos.length) {
				for (let i = 0; i < item.archivos.length; i++) {
					for (let j = 0; j < this.state.listar_archivo_integracion_expediente.length; j++) {
						if (Number(this.state.archivos[i].cve_doc) === Number(this.state.listar_archivo_integracion_expediente[j].cve_doc)) {
							this.state.listar_archivo_integracion_expediente[j].formato = item.archivos[i].formato;
							this.state.listar_archivo_integracion_expediente[j].titulo = item.archivos[i].titulo;
							this.state.listar_archivo_integracion_expediente[j].original = item.archivos[i].original;
							this.state.listar_archivo_integracion_expediente[j].copia = item.archivos[i].copia;
							this.state.listar_archivo_integracion_expediente[j].id_archivo = item.archivos[i].id_archivo;
							this.state.listar_archivo_integracion_expediente[j].path = item.archivos[i].archivo;
							this.state.listar_archivo_integracion_expediente[j].descripcion = item.archivos[i].descripcion;
						}
					}
				}
			}
			
			console.log("this.state.listar_archivo_integracion_expediente::::::", this.state.listar_archivo_integracion_expediente);
		});
		this.open();
	};
	
	ListSemestre = () => {
		return new Promise((resolve) => {
			CatService.ListSemestre().then((response) => {
				this.setState({
					list_cat_semestre: response.data
				});
				resolve(true);
			});
		});
	}
	
	ListGeneracion = () => {
		return new Promise((resolve) => {
			CatService.ListGeneracion().then((response) => {
				this.setState({
					list_cat_generacion: response.data
				});
				resolve(true);
			});
		});
	}
	
	ListCicloEscolar = () => {
		return new Promise((resolve) => {
			CatService.ListCicloEscolar().then((response) => {
				this.setState({
					list_cat_ciclo_escolar: response.data
				});
				resolve(true);
			});
		});
	}
	
	ListEspecialidad = () => {
		return new Promise((resolve) => {
			CatService.ListEspecialidad().then((response) => {
				this.setState({
					list_cat_especialidad: response.data
				});
				resolve(true);
			});
		});
	}
	
	ListModalidad = () => {
		return new Promise((resolve) => {
			CatService.ListModalidad().then((response) => {
				this.setState({
					list_cat_modalidad: response.data
				});
				resolve(true);
			});
		});
	}
	
	ListSexo = () => {
		return new Promise((resolve) => {
			CatService.ListSexo().then((response) => {
				this.setState({
					list_cat_sexo: response.data
				});
				resolve(true);
			});
		});
		
	}
	
	ListEstado = () => {
		return new Promise((resolve) => {
			CatService.ListEstado().then((response) => {
				this.setState({
					list_cat_estado: response.data
					
				});
				resolve(true);
			});
		});
	}
	
	ListMunicipio = (id_cat_estado, tipo) => {
		
		let variable = '';
		
		if (tipo === 1) {
			variable = 'list_cat_municipio_nacimiento';
		} else {
			variable = 'list_cat_municipio';
		}
		return new Promise((resolve) => {
			CatService.ListMunicipio(id_cat_estado).then((response) => {
				this.setState({
					[variable]: response.data
				});
				resolve(true);
			});
		});
	}
	
	ListarArchivoIntegracionExpediente = () => {
		return new Promise((resolve) => {
			CatService.ListArchivoIntegracionExpediente().then((response) => {
				this.setState({
					listar_archivo_integracion_expediente: response.data
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_archivo_integracion_expediente: []
				});
			});
		})
	}
	
	removefilepromotor = (item, key) => {
		// console.log(item, key);
		var arr_temp = FieldsJs.Copy(this.state.listar_archivo_integracion_expediente);
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, '¿Deseas borrar este archivo?').then((r) => {
			if (r.button === 'Aceptar') {
				arr_temp.splice(key, 1);
				this.setState({
					listar_archivo_integracion_expediente: arr_temp
				});
			}
		});
	};
	
	disabledFieldPromotorIntegracionExpediente = () => {
		
		let disabledFieldPromotorIntegracionExpediente = true;
		
		if (FieldsJs.inArray([1, 2, 3, 13], this.Usr.id_cat_tipo_usuario)) {
			disabledFieldPromotorIntegracionExpediente = false;
		}
		
		return disabledFieldPromotorIntegracionExpediente;
	};
	
	$BroadcastModalSeleccionarArchivoElaboracionPropuesta = (data) => {
		console.log(data);
		if (data.status) {
			switch (data.accion) {
				case "add_archivo_elaboracion_propuesta":
					let arr_temp_add = this.state.listar_archivo_integracion_expediente;
					if (Number(data.cve_doc) > 0) {
						for (let i = 0; i < arr_temp_add.length; i++) {
							if (Number(arr_temp_add[i].cve_doc) === Number(data.cve_doc)) {
								arr_temp_add[i].id_alumnos_documento = data.id_alumnos_documento;
								arr_temp_add[i].cve_doc = data.cve_doc;
								arr_temp_add[i].titulo = data.titulo;
								arr_temp_add[i].original = data.original;
								arr_temp_add[i].copia = data.copia;
								arr_temp_add[i].descripcion = data.descripcion;
								arr_temp_add[i].base64Tipo = data.base64Tipo;
								arr_temp_add[i].archivo = data.archivo;
								arr_temp_add[i].formato = data.formato;
								arr_temp_add[i].file = data.file;
							}
						}
					} else {
						arr_temp_add.push({
							id_alumnos_documento: data.id_alumnos_documento,
							cve_doc: data.cve_doc,
							titulo: data.titulo,
							original: data.original,
							copia: data.copia,
							descripcion: data.descripcion,
							base64Tipo: data.base64Tipo,
							archivo: data.archivo,
							formato: data.formato,
							file: data.file
						});
					}
					this.setState({
						listar_archivo_integracion_expediente: arr_temp_add
					});
					break;
				case "update_archivo_elaboracion_propuesta":
					let arr_temp_update = this.state.listar_archivo_integracion_expediente;
					for (let i = 0; i < arr_temp_update.length; i++) {
						if (arr_temp_update[i].id_alumnos_documento === data.id_alumnos_documento) {
							arr_temp_update[i].titulo = data.titulo;
							arr_temp_update[i].original = data.original;
							arr_temp_update[i].copia = data.copia;
							arr_temp_update[i].descripcion = data.descripcion;
							arr_temp_update[i].base64Tipo = data.base64Tipo;
							arr_temp_update[i].archivo = data.archivo;
							arr_temp_update[i].formato = data.formato;
							arr_temp_update[i].file = data.file;
						}
					}
					this.setState({
						listar_archivo_integracion_expediente: arr_temp_update
					});
					break;
				default:
					this.setState({
						listar_archivo_integracion_expediente: []
					});
			}
		}
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_alumno}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Alumno'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									
									helperText="Requerido"
									name="id_zona"
									label="Selecciona la zona"
									value={this.state.id_zona}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_zona.map(option => (
										<option key={option.id_zona} value={option.id_zona}>
											{option.zona +' '+ option.orientacion}
										</option>
									))}
								</TextField>
							
							</Grid>*/}
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									type={'text'}
									fullWidth
									name="curp"
									onChange={this.handleChange}
									// helperText="Requerido"
									label="CURP" autoComplete={'off'}
									value={this.state.curp}
									inputProps={{maxLength: 18}}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="nombre"
										           onChange={this.handleChange}
										           label="Nombre" autoComplete={'off'}
										           value={this.state.nombre}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="apellido_paterno"
										           onChange={this.handleChange}
										           label="Apellido Paterno" autoComplete={'off'}
										           value={this.state.apellido_paterno}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="apellido_materno"
										           onChange={this.handleChange}
										           label="Apellido Materno" autoComplete={'off'}
										           value={this.state.apellido_materno}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PhoneOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="telefono"
										           onChange={this.handleChange}
										           label="Teléfono" autoComplete={'off'}
										           value={this.state.telefono}
										           inputProps={{maxLength: 10}}
										           onKeyPress={EnteroSolo}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<DeviceHubOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_sexo"
											label="Sexo"
											value={this.state.id_cat_sexo}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_sexo.map(option => (
												<option key={option.id_cat_sexo}
												        value={option.id_cat_sexo}>
													{option.sexo}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AlternateEmailOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="correo_electronico"
										           onChange={this.handleChange}
										           label="Correo electrónico" autoComplete={'off'}
										           value={this.state.correo_electronico}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={(e) => {
												this.handleChange(e);
												this.ListMunicipio(e.target.value, 0);
											}}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_estado"
											label="Estado de nacimiento"
											value={this.state.id_cat_estado}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_estado.map(option => (
												<option key={option.id_cat_estado}
												        value={option.id_cat_estado}>
													{option.estado}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrongOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_municipio"
											label="Municipio de nacimiento"
											value={this.state.id_cat_municipio}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_municipio.map(option => (
												<option key={option.id_cat_municipio}
												        value={option.id_cat_municipio}>
													{option.municipio}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<CalendarTodayOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											label="Fecha nacimiento"
											value={this.state.fecha_nacimiento}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_nacimiento');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								
								<TextField
									name="escuela_procedencia"
									label="Escuela de procedencia"
									type="text"
									fullWidth
									value={this.state.escuela_procedencia}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								
								<TextField
									name="direccion_escuela_procedencia"
									label="Direccion de la escuela"
									type="text"
									fullWidth
									value={this.state.direccion_escuela_procedencia}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								
								<TextField
									name="clave"
									label="Clave de la escuela"
									type="text"
									fullWidth
									value={this.state.clave}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								
								<TextField
									name="promedio"
									label="Promedio"
									type="text"
									fullWidth
									value={this.state.promedio}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								
								<TextField
									name="matricula"
									label="Matricula"
									type="text"
									fullWidth
									value={this.state.matricula}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								
								<TextField
									name="referencia_bancaria"
									label="Referencia bancaria"
									type="text"
									fullWidth
									value={this.state.referencia_bancaria}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Divider/>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Typography variant={'h5'} className={'px-14 margin-15-T text-left'}>
									Datos del tutor
								</Typography>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="nombre_tutor"
										           onChange={this.handleChange}
										           label="Nombre" autoComplete={'off'}
										           value={this.state.nombre_tutor}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="apellido_paterno_tutor"
										           onChange={this.handleChange}
										           label="Apellido Paterno" autoComplete={'off'}
										           value={this.state.apellido_paterno_tutor}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="apellido_materno_tutor"
										           onChange={this.handleChange}
										           label="Apellido Materno" autoComplete={'off'}
										           value={this.state.apellido_materno_tutor}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PhoneOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="telefono_tutor"
										           onChange={this.handleChange}
										           label="Teléfono" autoComplete={'off'}
										           value={this.state.telefono_tutor}
										           inputProps={{maxLength: 10}}
										           onKeyPress={EnteroSolo}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Home className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											multiline
											rows={3}
											name="domicilio_tutor"
											onChange={this.handleChange}
											label="Domicilio" autoComplete={'off'}
											value={this.state.domicilio_tutor}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Business className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											multiline
											rows={3}
											name="lugar_trabajo_tutor"
											onChange={this.handleChange}
											label="Lugar de trabajo" autoComplete={'off'}
											value={this.state.lugar_trabajo_tutor}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Business className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											multiline
											rows={3}
											name="domicilio_trabajo_tutor"
											onChange={this.handleChange}
											label="Dirección del trabajo" autoComplete={'off'}
											value={this.state.domicilio_trabajo_tutor}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<LocalHospital className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											multiline
											rows={3}
											name="telefono_emergencia_tutor"
											onChange={this.handleChange}
											inputProps={{maxLength: 10}}
											onKeyPress={EnteroSolo}
											label="En caso de emergencia llamar al:" autoComplete={'off'}
											value={this.state.telefono_emergencia_tutor}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<LocalHospital className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="domicilio_emergencia_tutor"
											onChange={this.handleChange}
											label="y/o al domicilio:" autoComplete={'off'}
											value={this.state.domicilio_emergencia_tutor}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Grid container spacing={1}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<Typography variant={'h5'} className={'px-14 margin-15-T text-left'}>
											Documentación
										</Typography>
									
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<div className={'w-100-26-px h-auto'}>
											<div className={'grid-20-65-px'}>
												
												{this.state.listar_archivo_integracion_expediente.map((item, key) =>
													<SeccionExpedientes
														key={key}
														especifico={{
															label: item.documento || null,
															is_required: Number(item.activo) === 1
														}}
														item={item}
														// tipo={disabledFieldPromotorRequerimientoCliente ? 'view' : (item.archivo_elaboracion_propuesta ? 'add' : 'edit')}
														tipo={this.state.tipo}
														removefilepromotor={this.removefilepromotor}
														index={key}
														$BroadcastModalSeleccionarArchivoElaboracionPropuesta={this.$BroadcastModalSeleccionarArchivoElaboracionPropuesta}
													/>
												)}
												
												{/*<SeccionExpedientes
												item={{}}
												tipo={'add'}
												especifico={{
													label: null,
													is_required: false
												}}
												removefilepromotor={this.removefilepromotor}
												$BroadcastModalSeleccionarArchivoElaboracionPropuesta={this.$BroadcastModalSeleccionarArchivoElaboracionPropuesta}
											/>*/}
											</div>
										</div>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Typography variant={'h5'} className={'px-14 margin-15-T text-left'}>
									Asignación
								</Typography>
							
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_semestre"
											label="Semestre"
											value={this.state.id_cat_semestre}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_semestre.map(option => (
												<option key={option.id_cat_semestre}
												        value={option.id_cat_semestre}>
													{option.semestre}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_especialidad"
											label="Especialidad"
											value={this.state.id_cat_especialidad}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_especialidad.map(option => (
												<option key={option.id_cat_especialidad}
												        value={option.id_cat_especialidad}>
													{option.especialidad}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_ciclo_escolar"
											label="Ciclo Escolar"
											value={this.state.id_cat_ciclo_escolar}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_ciclo_escolar.map(option => (
												<option key={option.id_cat_ciclo_escolar}
												        value={option.id_cat_ciclo_escolar}>
													{option.ciclo_escolar}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_generacion"
											label="Generación"
											value={this.state.id_cat_generacion}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_generacion.map(option => (
												<option key={option.id_cat_generacion}
												        value={option.id_cat_generacion}>
													{option.generacion}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_modalidad"
											label="Modalidad"
											value={this.state.id_cat_modalidad}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_modalidad.map(option => (
												<option key={option.id_cat_modalidad}
												        value={option.id_cat_modalidad}>
													{option.modalidad}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_alumno}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalAlumno.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalAlumno;
