import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";

/*

<EstiloBotonAccesoDirectoInicio
	debug={true}
	widthImg={100}
	marginImg={20}
	widthContent={300}
	heightContent={70}
	heightText={50}
	backgroundContent={'gray'}
	backgroundText={'#209fd0'}
	label={'Ejemplo de texto'}
	iconRight={acceso_lista_venta}
	iconLeft={acceso_nueva_venta}
/>
<br/>
<br/>
<EstiloBotonAccesoDirectoInicio
	debug={true}
	widthImg={100}
	marginImg={20}
	widthContent={300}
	heightContent={70}
	heightText={50}
	backgroundContent={'gray'}
	backgroundText={'#209fd0'}
	label={'Nueva orden de venta'}
	iconRight={acceso_lista_venta}
/>
<br/>
<br/>
<EstiloBotonAccesoDirectoInicio
	debug={true}
	widthImg={100}
	marginImg={20}
	widthContent={300}
	heightContent={70}
	heightText={50}
	backgroundContent={'gray'}
	backgroundText={'#209fd0'}
	label={'Lista de ventas'}
	iconLeft={acceso_nueva_venta}
/>

*/
class EstiloBotonAccesoDirectoInicio extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.state = {
			zoomItHover: ""
		};
	}
	
	bigImg = () => {
		this.setState({
			zoomItHover: "zoomItHover"
		});
	};
	
	normalImg = () => {
		this.setState({
			zoomItHover: ""
		});
	};
	
	render() {
		
		let debug = this.props.debug || false;
		
		let widthImg = this.props.widthImg || 100;
		let marginImg = this.props.marginImg || 20;
		
		let styleIconLeft = {};
		let styleText = {
			cursor: "pointer",
		};
		let styleIconRight = {};
		
		if (this.props.iconLeft && this.props.iconRight) { // C E N T E R
			styleIconLeft = {
				cursor: "pointer",
				background: debug ? "rgba(255, 0, 0, 0.50)" : undefined,
				width: `${widthImg}px`,
				zIndex: debug ? 1 : 2,
			};
			styleText = {
				cursor: "pointer",
				height: this.props.heightText || undefined,
				background: debug ? "rgba(255, 165, 0, 0.50)" : (this.props.backgroundText || "rgba(128, 128, 128, 0.50)"),
				width: `calc(100% - ${widthImg}px - ${widthImg}px + ${(marginImg * 2)}px)`,
				textAlign: "center",
				marginLeft: `-${marginImg}px`,
				paddingLeft: `${marginImg}px`,
				marginRight: `-${marginImg}px`,
				paddingRight: `${marginImg}px`,
				zIndex: debug ? 2 : 1,
			};
			styleIconRight = {
				cursor: "pointer",
				background: debug ? "rgba(255, 0, 0, 0.50)" : undefined,
				width: `${widthImg}px`,
				zIndex: debug ? 1 : 2,
			};
		} else if (this.props.iconLeft) { // L E F T
			styleIconLeft = {
				cursor: "pointer",
				background: debug ? "rgba(255, 0, 0, 0.50)" : undefined,
				width: `${widthImg}px`,
				zIndex: debug ? 1 : 2,
			};
			styleText = {
				cursor: "pointer",
				height: this.props.heightText || undefined,
				background: debug ? "rgba(255, 165, 0, 0.50)" : (this.props.backgroundText || "rgba(128, 128, 128, 0.50)"),
				width: `calc(100% - ${widthImg}px + ${marginImg}px)`,
				textAlign: "right",
				marginLeft: `-${marginImg}px`,
				paddingLeft: `${marginImg}px`,
				marginRight: `0px`,
				paddingRight: `${marginImg}px`,
				zIndex: debug ? 2 : 1,
			};
			styleIconRight = {};
		} else if (this.props.iconRight) { // R I G H T
			styleIconLeft = {};
			styleText = {
				cursor: "pointer",
				height: this.props.heightText || undefined,
				background: debug ? "rgba(255, 165, 0, 0.50)" : (this.props.backgroundText || "rgba(128, 128, 128, 0.50)"),
				width: `calc(100% - ${widthImg}px + ${marginImg}px)`,
				textAlign: "left",
				marginLeft: `0px`,
				paddingLeft: `${marginImg}px`,
				marginRight: `-${marginImg}px`,
				paddingRight: `${marginImg}px`,
				zIndex: debug ? 2 : 1,
			};
			styleIconRight = {
				cursor: "pointer",
				background: debug ? "rgba(255, 0, 0, 0.50)" : undefined,
				width: `${widthImg}px`,
				zIndex: debug ? 1 : 2,
			};
		}
		
		return (
			<Fragment>
				<Grid container spacing={0} direction={"row"} alignItems={"center"} alignContent={"center"} style={{
					background: debug ? "rgba(128, 128, 128, 0.50)" : (this.props.backgroundContent || undefined),
					width: this.props.widthContent || undefined,
					height: this.props.heightContent || undefined,
				}}>
					{this.props.iconLeft ? (
						<Grid item style={styleIconLeft}>
							<img
								src={this.props.iconLeft}
								alt={this.props.iconLeft}
								height={'100%'}
								width={'100%'}
								className={`zoomIt ${this.state.zoomItHover}`}
								onMouseOver={this.bigImg}
								onMouseOut={this.normalImg}
								onClick={this.props.onClick}
							/>
						</Grid>
					) : null}
					<Grid item style={styleText} onMouseOver={this.bigImg} onMouseOut={this.normalImg} onClick={this.props.onClick}>
						<Grid container spacing={0} direction={"row"} alignItems={"center"} alignContent={"center"} style={{height: "100%"}}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{this.props.label}
							</Grid>
						</Grid>
					</Grid>
					{this.props.iconRight ? (
						<Grid item style={styleIconRight}>
							<img
								src={this.props.iconRight}
								alt={this.props.iconRight}
								height={'100%'}
								width={'100%'}
								className={`zoomIt ${this.state.zoomItHover}`}
								onMouseOver={this.bigImg}
								onMouseOut={this.normalImg}
								onClick={this.props.onClick}
							/>
						</Grid>
					) : null}
				</Grid>
			</Fragment>
		);
	}
	
}

EstiloBotonAccesoDirectoInicio.propTypes = {
	debug: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.oneOf([null]),
	]),
	widthImg: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	marginImg: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	widthContent: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.string.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	heightContent: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.string.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	heightText: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.string.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	label: PropTypes.oneOfType([
		PropTypes.element.isRequired,
		PropTypes.string.isRequired,
	]),
	backgroundContent: PropTypes.string,
	backgroundText: PropTypes.string,
	iconLeft: PropTypes.string,
	iconRight: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};
export default EstiloBotonAccesoDirectoInicio;
