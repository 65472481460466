import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import {KeyboardDatePicker} from '@material-ui/pickers';
import Button from "@material-ui/core/Button";

import {CadenaDomicilio, EnteroSolo, FieldsJs, FloatSolo, MinMax} from '../../../../settings/General/General';

import AutoCompleteCliente from './AutoCompleteCliente';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import {DeleteOutlined, DeviceHubOutlined} from '@material-ui/icons';

import add_cliente_venta from '../../../../assets/img/icons/add-cliente-venta.svg';
import edit_cliente_venta from '../../../../assets/img/icons/edit-cliente-venta.svg';
import view_cliente_venta from '../../../../assets/img/icons/view-cliente-venta.svg';
import agregar_producto_requerimiento_adicional from '../../../../assets/img/icons/agregar-producto-atributo.svg';
import carnet_identificacion from '../../../../assets/img/icons/carnet-de-identidad.svg';
import Input from "@material-ui/core/Input";
import Divider from "@material-ui/core/Divider";
import ModalAtributoRroducto from "./ModalAtributoRroducto";
import BtnMasAdd from "../../../Include/MiniComponents/BtnMasAdd"
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import NativeSelect from "@material-ui/core/NativeSelect";
import ModalCliente from "../../Cliente/Includes/ModalCliente";

const styles = {
	root: {
		background: "transparent",
		color: "black"
	},
	input: {
		color: "black"
	},
	input_number: {
		color: "black",
		textAlign: "right",
		alignText: "right",
	}
};


class ContenidoOrdenVenta extends Component {
	
	
	render() {
		
		const btn_add = {
			backgroundImage: `url(${add_cliente_venta})`,
			opacity: 1
		};
		
		const btn_edit = {
			backgroundImage: `url(${edit_cliente_venta})`,
			opacity: this.props.form.form_seleccionado.id_cliente > 1 ? 1 : 0.5
		};
		
		const btn_view = {
			backgroundImage: `url(${view_cliente_venta})`,
			opacity: this.props.form.form_seleccionado.id_cliente > 1 ? 1 : 0.5
		};
		
		const btn_agregar_producto_requerimiento_adicional = {
			backgroundImage: `url(${agregar_producto_requerimiento_adicional})`,
			opacity: 1
		};
		
		const lista_vacia_datos_cliente = {
			backgroundImage: `url(${carnet_identificacion})`,
			opacity: 0.5
		};
		
		const desglose = this.props.updateDesglose();
		
		const {classes} = this.props;
		
		let disabledInputVenta = !!this.props.id_venta;
		
		let disabledInputVentaEditar = !!this.props.id_venta && !this.props.editar;
		
		return (
			<Fragment>
				
				<section className={'contentenido-nueva-venta w-auto padding-20-L padding-20-R'}>
					
					<Grid container spacing={3} direction="row" justify="flex-end" alignItems="flex-end"
					      className={'h-100 margin-0-B margin-0-T'}>
						
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={'h-100'}>
							
							<Grid container spacing={0} className={'h-100'}>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-60-px'}>
									
									{disabledInputVentaEditar ? (
										<Fragment>
											{this.props.form.numero_pedido || this.props.form.numero_cotizacion ? (
												<Typography className={'text-left text-danger px-20'}>
													{this.props.form.numero_pedido || this.props.form.numero_cotizacion}
												</Typography>
											) : ''}
											<Typography className={'text-left px-14'}>
												{this.props.form.form_seleccionado.nombre} {this.props.form.form_seleccionado.apellido_paterno} {this.props.form.form_seleccionado.apellido_materno}
											</Typography>
										</Fragment>
									) : (
										<div className={'row-flex h-52-px'}>
											<div className="w-100-120-px">
												<AutoCompleteCliente
													handleChangeSingle={this.props.handleChangeSingle}
													suggestions={this.props.suggestions}
													value={this.props.form.id_alumno || 0}
													name={'id_alumno'} id={'id_alumno'}
													label={'nombre_completo'}
													helpertext={'Alumno'}
													placeholder={'Alumno'}
												/>
											</div>
											
											<div className="w-40-px vertical-inline">
												<div className={'w-100 v-end'} align={'right'}>
													<ModalCliente
														tipo={'view'}
														item={this.props.form.form_seleccionado}
														RefreshList={() => this.props.RefreshListCliente(null)}
														showSnackBars={this.props.showSnackBars}
														componente={(
															<div
																className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
																style={btn_view}
															/>
														)}
													/>
												</div>
											</div>
											<div className="w-40-px vertical-inline">
												<div className={'w-100 v-end'} align={'right'}>
													<ModalCliente
														tipo={'edit'}
														item={this.props.form.form_seleccionado}
														RefreshList={() => this.props.RefreshListCliente(null)}
														showSnackBars={this.props.showSnackBars}
														componente={(
															<div
																className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
																style={btn_edit}
															/>
														)}
													/>
												</div>
											</div>
											<div className="w-40-px vertical-inline">
												<div className={'w-100 v-end'} align={'right'}>
													<ModalCliente
														tipo={'add'}
														item={{}}
														RefreshList={(id_cliente) => this.props.RefreshListCliente(id_cliente)}
														showSnackBars={this.props.showSnackBars}
														componente={(
															<div
																className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
																style={btn_add}
															/>
														)}
													/>
												</div>
											</div>
										</div>
									)}
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-60-px'}>
									
									<Card className={'borde-punteado-gray-1 padding-0 h-100'}>
										<CardContent className={'padding-5 h-100-12-px'}>
											
											{this.props.form.form_seleccionado.id_cliente !== 1 && (CadenaDomicilio(this.props.form.form_seleccionado) || this.props.form.form_seleccionado.rfc || this.props.form.form_seleccionado.correo_electronico || this.props.form.form_seleccionado.telefono || this.props.form.form_seleccionado.celular) ? (
												<Fragment>
													
													<div className={'w-100 h-100 vertical-inline'}>
														<div className={'w-100 v-center'} align={'center'}>
															
															{this.props.form.form_seleccionado.rfc ? (
																<Typography className={'px-14 text-left l-h-14'}>
																	R.F.C.: {this.props.form.form_seleccionado.rfc}
																</Typography>
															) : ''}
															
															{this.props.form.form_seleccionado.correo_electronico || this.props.form.form_seleccionado.telefono || this.props.form.form_seleccionado.celular ? (
																<Typography className={'px-14 text-left l-h-14'}>
																	{this.props.form.form_seleccionado.correo_electronico ? (
																		<span>{this.props.form.form_seleccionado.correo_electronico} </span>) : ''}
																	{this.props.form.form_seleccionado.telefono ? (
																		<span>|  Tel: {this.props.form.form_seleccionado.telefono} </span>) : ''}
																	{this.props.form.form_seleccionado.celular ? (
																		<span>|  Cel: {this.props.form.form_seleccionado.celular}</span>) : ''}
																</Typography>
															) : ''}
															
															{/*{CadenaDomicilio(this.props.form.form_seleccionado) ? (
																<Typography className={'px-12 text-left l-h-14'}>
																	{CadenaDomicilio(this.props.form.form_seleccionado)}
																</Typography>
															) : ''}*/}
														
														</div>
													</div>
												
												</Fragment>
											) : (
												<Fragment>
													<div className={'w-100 h-100 vertical-inline'}>
														<div className={'w-100 v-center'} align={'center'}>
															
															<div style={lista_vacia_datos_cliente}
															     className={'w-30-px h-30-px bg-img-cover-x-center-y-center'}/>
															
															<Typography color="textSecondary"
															            className={'px-14 text-center l-h-14'}>
																Venta para el publico en general
															</Typography>
														
														</div>
													</div>
												
												</Fragment>
											)}
										
										</CardContent>
									</Card>
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-40-px'}>
									
									<RadioGroup row aria-label="position"
									            name="factura_cotizacion_nota_sencilla_interno"
									            value={this.props.form.factura_cotizacion_nota_sencilla_interno}
									            onChange={(e) => this.props.handleChange(e)}>
										<FormControlLabel
											className={'px-12'}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value="factura"
											label="Factura"
											disabled={disabledInputVenta}
										/>
										<FormControlLabel
											className={'px-12'}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value="cotizacion"
											label="Cotización"
											disabled={disabledInputVenta}
										/>
										<FormControlLabel
											className={'px-12'}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value="nota_sencilla"
											label="Nota sencilla"
											disabled={disabledInputVenta}
										/>
										{/*<FormControlLabel
											className={'px-12'}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value="interno"
											label="Interno"
											disabled={disabledInputVenta}
										/>*/}
									</RadioGroup>
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-60-px'}>
									
									<Grid container spacing={2} direction="row" justify="center" alignItems="center">
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											{/*<Card className={'padding-0'} style={{height: 40}}>
												<CardContent className={'padding-4'} style={{height: '100%'}}>
													<FormControlLabel
														className={'h-30-px px-12 text-left'}
														control={
															<Checkbox
																fontSize="small"
																type="checkbox"
																color="primary"
																name={'por_hora'}
																checked={!!this.props.form.por_hora}
																onChange={(e) => this.props.handleChange(e)}
																disabled={disabledInputVenta}
															/>
														}
														label={'Definir x hrs.'}
														labelPlacement="end"
													/>
												</CardContent>
											</Card>*/}
											
											<div className={'row-flex h-52-px'}>
												<div className="w-100-120-px">
													<AutoCompleteCliente
														handleChangeSingle={this.props.handleChangeSingle}
														suggestions={this.props.suggestions}
														value={this.props.form.id_alumno || 0}
														name={'id_alumno'} id={'id_alumno'}
														label={'nombre_completo'}
														helpertext={'Receptor de la factura'}
														placeholder={'Receptor de la factura'}
													/>
												</div>
												
												<div className="w-40-px vertical-inline">
													<div className={'w-100 v-end'} align={'right'}>
														<ModalCliente
															tipo={'view'}
															item={this.props.form.form_seleccionado}
															RefreshList={() => this.props.RefreshListCliente(null)}
															showSnackBars={this.props.showSnackBars}
															componente={(
																<div
																	className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
																	style={btn_view}
																/>
															)}
														/>
													</div>
												</div>
												<div className="w-40-px vertical-inline">
													<div className={'w-100 v-end'} align={'right'}>
														<ModalCliente
															tipo={'edit'}
															item={this.props.form.form_seleccionado}
															RefreshList={() => this.props.RefreshListCliente(null)}
															showSnackBars={this.props.showSnackBars}
															componente={(
																<div
																	className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
																	style={btn_edit}
																/>
															)}
														/>
													</div>
												</div>
												<div className="w-40-px vertical-inline">
													<div className={'w-100 v-end'} align={'right'}>
														<ModalCliente
															tipo={'add'}
															item={{}}
															RefreshList={(id_cliente) => this.props.RefreshListCliente(id_cliente)}
															showSnackBars={this.props.showSnackBars}
															componente={(
																<div
																	className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
																	style={btn_add}
																/>
															)}
														/>
													</div>
												</div>
											</div>
										</Grid>
									
									
									</Grid>
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100-230-px'}>
									
									<div className={'borde-punteado-gray-1 h-100-2-px'}>
										<Card className={'padding-0 h-100'}>
											<CardContent className={'padding-0 h-100'}>
												
												<div style={{
													overflowY: "auto",
													overflowX: "hidden",
													height: "calc(100% - 30px)",
													padding: "15px"
												}}>
													<Fragment>
														<Grid container spacing={1} justify="flex-end"
														      alignItems="flex-end">
															
															{/*<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<TextField
																	multiline
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Producto"
																	}}
																	
																	name={'_producto'}
																	onChange={this.props.handleChange}
																	value={this.props.form._producto}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>*/}
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<NativeSelect
																	fullWidth
																	name="_id_cat_grupo"
																	value={this.props.form._id_cat_grupo}
																	onChange={this.props.handleChange}
																	inputProps={{
																		'aria-label': '_id_cat_grupo',
																	}}
																>
																	<option value="">Tipo Pago</option>
																	{this.props.cat_grupo.map(option => (
																		<option
																			key={option.id_cat_grupo}
																			value={option.id_cat_grupo}
																			children={option.grupo}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<NativeSelect
																	fullWidth
																	name="_id_cat_grupo"
																	value={this.props.form._id_cat_grupo}
																	onChange={this.props.handleChange}
																	inputProps={{
																		'aria-label': '_id_cat_grupo',
																	}}
																>
																	<option value="">Forma de Pago</option>
																	{this.props.cat_grupo.map(option => (
																		<option
																			key={option.id_cat_grupo}
																			value={option.id_cat_grupo}
																			children={option.grupo}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<NativeSelect
																	fullWidth
																	name="_id_cat_grupo"
																	value={this.props.form._id_cat_grupo}
																	onChange={this.props.handleChange}
																	inputProps={{
																		'aria-label': '_id_cat_grupo',
																	}}
																>
																	<option value="">Cuatrimestre</option>
																	{this.props.cat_grupo.map(option => (
																		<option
																			key={option.id_cat_grupo}
																			value={option.id_cat_grupo}
																			children={option.grupo}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<NativeSelect
																	fullWidth
																	name="_id_cat_grupo"
																	value={this.props.form._id_cat_grupo}
																	onChange={this.props.handleChange}
																	inputProps={{
																		'aria-label': '_id_cat_grupo',
																	}}
																>
																	<option value="">Grupo</option>
																	{this.props.cat_grupo.map(option => (
																		<option
																			key={option.id_cat_grupo}
																			value={option.id_cat_grupo}
																			children={option.grupo}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<TextField
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Cantidad"
																	}}
																	onKeyPress={EnteroSolo}
																	name={'_cantidad'}
																	onChange={this.props.handleChange}
																	value={this.props.form._cantidad}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<TextField
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	InputProps={{
																		inputProps: {
																			className: classes.input,
																			placeholder: "0"
																		},
																		startAdornment: <InputAdornment
																			position="start">$</InputAdornment>
																	}}
																	onKeyPress={FloatSolo}
																	
																	name={'_precio_unitario'}
																	onChange={this.props.handleChange}
																	value={this.props.form._precio_unitario}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<TextField
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Descripción"
																	}}
																	multiline
																	rows={2}
																	name={'_descripcion'}
																	onChange={this.props.handleChange}
																	value={this.props.form._descripcion}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															
															
															{/*<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																<NativeSelect
																	name="_id_cat_grupo"
																	value={this.props.form._id_cat_grupo}
																	onChange={this.props.handleChange}
																	inputProps={{
																		'aria-label': '_id_cat_grupo',
																	}}
																>
																	<option value="">Selecciona un grupo
																	</option>
																	{this.props.cat_grupo.map(option => (
																		<option
																			key={option.id_cat_grupo}
																			value={option.id_cat_grupo}
																			children={option.grupo}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															*/}
															{/*{this.props.cat_tipo_requerimiento.map((item, key) => (
																<Fragment key={key}>
																	<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																		
																		<Grid container spacing={0}
																		      justify="flex-end"
																		      alignItems="flex-end">
																			<Grid item
																			      className={(item.con_costo_adicional === 1 ? 'w-100-50-px' : 'w-100')}>
																				<FormGroup row>
																					<FormControlLabel
																						className={'h-30-px px-12 text-left'}
																						control={
																							<Checkbox
																								fontSize="small"
																								type="checkbox"
																								color="primary"
																								name={'tipo_requerimiento__' + key}
																								onChange={(e) => this.props.handleChange(e, 'cat_tipo_requerimiento', 'checked')}
																								checked={!!item.checked}
																								disabled={disabledInputVentaEditar}
																							/>
																						}
																						label={item.tipo_requerimiento}
																						labelPlacement="end"
																					/>
																				</FormGroup>
																			</Grid>
																			{item.con_costo_adicional === 1 ? (
																				<Grid item className={'w-50-px'}>
																					<Input placeholder="0.00"
																					       className={'px-14 text-right'}
																					       inputProps={{
																						       'aria-label': 'Costo adicional',
																					       }}
																					       name={'costo_adicional__' + key}
																					       onChange={(e) => this.props.handleChange(e, 'cat_tipo_requerimiento', 'costo_adicional')}
																					       disabled={disabledInputVentaEditar || !item.checked}
																					       value={item.costo_adicional}
																					/>
																				</Grid>
																			) : ""}
																		</Grid>
																	
																	</Grid>
																</Fragment>
															))}
															*/}
															{/*{this.props.producto_requerimiento_adicional.map((item, key) => (
																<Grid key={key} item xs={12} sm={12} md={12} lg={12}
																      xl={12}>
																	
																	<Grid container spacing={0}
																	      justify="center"
																	      alignItems="center">
																		
																		<Grid item className={'w-30-px'}
																		      align={'left'}>
																			<div className={'cursor-pointer'}
																			     style={{
																				     padding: '6px',
																				     background: 'red',
																				     height: '6px',
																				     width: '6px',
																				     lineHeight: '6px',
																				     color: 'white',
																				     borderRadius: '100%',
																				     fontSize: '12px',
																			     }}
																			     onClick={() => this.props.eliminar_producto_requerimiento_adicional(key)}>x
																			</div>
																		</Grid>
																		
																		<Grid item className={'w-100-80-px'}>
																			<Typography
																				className={'text-left px-14'}>
																				{item.requerimiento_adicional}
																			</Typography>
																		</Grid>
																		
																		<Grid item className={'w-50-px'}>
																			<Input placeholder="0.00"
																			       className={'px-14 text-right'}
																			       inputProps={{
																				       'aria-label': 'Costo adicional',
																			       }}
																			       value={item.costo_adicional}
																			       name={'costo_adicional__' + key}
																			       onChange={(e) => this.props.handleChange(e, 'producto_requerimiento_adicional', 'costo_adicional')}
																			       disabled={disabledInputVentaEditar}
																			/>
																		</Grid>
																	
																	</Grid>
																
																</Grid>
															))}*/}
															
															{/*{!disabledInputVentaEditar ? (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																	
																	<div className={'row-flex'}>
																		<div className="w-100-40-px">
																			<TextField
																				fullWidth
																				type="text"
																				margin="none"
																				className={classes.root}
																				inputProps={{
																					className: classes.input,
																					placeholder: "Otros requerimientos",
																				}}
																				
																				name={'_otro_requerimiento'}
																				onChange={this.props.handleChange}
																				value={this.props.form._otro_requerimiento}
																				disabled={disabledInputVentaEditar}
																			/>
																		</div>
																		<div className="w-40-px vertical-inline">
																			<div className={'w-100 v-end'}
																			     align={'right'}>
																				<div
																					className={'w-30-px h-30-px b-r-100 bg-img-cover-x-center-y-center cursor-pointer'}
																					onClick={this.props.agregar_producto_requerimiento_adicional}
																					style={btn_agregar_producto_requerimiento_adicional}/>
																			</div>
																		</div>
																	</div>
																
																</Grid>
															) : ''}*/}
															
															{!disabledInputVentaEditar ? (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}
																      align={'center'} className={'padding-15-T'}>
																	<Button variant="contained" color="primary"
																	        onClick={this.props.mas}
																	        className={'btn-default-primary text-transform-none'}>
																		Agregar
																	</Button>
																</Grid>
															) : ''}
														
														</Grid>
													</Fragment>
												</div>
											
											</CardContent>
										</Card>
									</div>
								
								</Grid>
							
							</Grid>
						
						</Grid>
						
						<Grid item xs={12} sm={12} md={8} lg={8} xl={8} className={'h-100'}>
							
							<Grid container spacing={0} className={'h-100'}>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-60-px text-left'}>
									
									<Typography variant={"h6"} component={"h6"}
									            className={'text-right text-black px-18 l-h-16'}>
										¿Como te enteraste?
									</Typography>
									
									<div align={'right'}>
										{this.props.cat_como_te_enteraste.map((item, key) => (
											<Fragment key={key}>
												<FormControlLabel className={'h-30-px margin-16-L margin-0-R px-14'}
												                  control={
													                  <Checkbox type="checkbox" color="primary"
													                            name={'como_te_enteraste__' + key}
													                            onChange={(e) => this.props.handleChange(e, 'cat_como_te_enteraste', 'checked')}
													                            checked={!!item.checked}
													                            disabled={disabledInputVentaEditar}
													                  />
												                  }
												                  label={item.como_te_enteraste}
												                  labelPlacement="end"
												/>
											</Fragment>
										))}
									</div>
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100-220-px'}>
									
									<article className={'borde-punteado-gray-1 bg-white'} style={{
										overflowX: "auto",
										height: "calc(100% - 15px)"
									}}>
										
										<table width={'100%'}>
											<thead>
											<tr>
												<th className={'padding-10-T padding-10-B px-14'}>Eliminar</th>
												<th className={'padding-10-T padding-10-B px-14'}>Grupo</th>
												<th className={'padding-10-T padding-10-B px-14'}>Producto</th>
												<th className={'padding-10-T padding-10-B px-14'}>Descripción</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={75}>Cantidad
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={120}>P.
													Unitario
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={120}>Costo adicional
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={75}>% Descuento
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={120}>Precio
												</th>
											</tr>
											</thead>
											<tbody>
											{this.props.productos.map((item, key) => (
												<Fragment key={key}>
													<tr className={key % 2 === 0 ? 'bg-lightgray' : 'bg-white'}>
														<td className={'padding-4'} align={'center'}>
															{!disabledInputVentaEditar ? (
																<div className={'cursor-pointer'} style={{
																	padding: '6px',
																	background: 'red',
																	height: '6px',
																	width: '6px',
																	lineHeight: '6px',
																	color: 'white',
																	borderRadius: '100%',
																	fontSize: '12px',
																}}
																     onClick={() => this.props.menos(item, key)}>x</div>
															) : ''}
														</td>
														<td className={'padding-4'}>
															<NativeSelect
																name={'id_cat_grupo__' + key}
																value={item.id_cat_grupo}
																onChange={(e) => this.props.handleChange(e, 'productos', 'id_cat_grupo')}
																inputProps={{
																	'aria-label': 'id_cat_grupo',
																}}
																disabled={disabledInputVentaEditar}
															>
																<option value="">Selecciona un grupo
																</option>
																{this.props.cat_grupo.map(option => (
																	<option
																		key={option.id_cat_grupo}
																		value={option.id_cat_grupo}
																		children={option.grupo}
																	/>
																))}
															</NativeSelect>
														</td>
														<td className={'padding-4'}>
															<TextField
																multiline
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "Producto"
																}}
																
																name={'venta_producto__' + key}
																onChange={(e) => this.props.handleChange(e, 'productos', 'venta_producto')}
																value={item.venta_producto}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4'}>
															<TextField
																multiline
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "Descripción"
																}}
																
																name={'venta_descripcion__' + key}
																onChange={(e) => this.props.handleChange(e, 'productos', 'venta_descripcion')}
																value={item.venta_descripcion}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4'}>
															<TextField
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "0"
																}}
																onKeyPress={EnteroSolo}
																
																name={'venta_cantidad__' + key}
																onChange={(e) => {
																	this.props.handleChange(e, 'productos', 'venta_cantidad');
																	this.props.updatePrecio();
																}}
																value={item.venta_cantidad}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4'}>
															<TextField
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																InputProps={{
																	inputProps: {
																		className: classes.input,
																		placeholder: "0"
																	},
																	startAdornment: <InputAdornment
																		position="start">$</InputAdornment>
																}}
																
																onKeyPress={FloatSolo}
																
																name={'venta_precio_unitario__' + key}
																onChange={(e) => {
																	this.props.handleChange(e, 'productos', 'venta_precio_unitario');
																	this.props.updatePrecio();
																}}
																value={item.venta_precio_unitario}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4 text-right'}>
															$ {FieldsJs.Currency(item.venta_costo_adicional)}
														</td>
														<td className={'padding-4 text-right'}>
															<TextField
																multiline
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "0",
																	maxLength: 10
																}}
																onKeyPress={(e) => {
																	MinMax(e, 0, 100);
																	EnteroSolo(e);
																}}
																
																name={'venta_porcentaje_descuento__' + key}
																onChange={(e) => {
																	this.props.handleChange(e, 'productos', 'venta_porcentaje_descuento');
																	this.props.updatePrecio();
																}}
																value={item.venta_porcentaje_descuento}
																// disabled={disabledInputVentaEditar}
																disabled={true}
															/>
														</td>
														<td className={'padding-4 text-right'}>
															<Fragment>
																<p className={'padding-0 margin-0 px-14 ff-arial'}>
																	${FieldsJs.Currency(item.venta_precio)}
																</p>
																{item.venta_descuento > 0 ? (
																	<p className={'padding-0 margin-0 px-9 text-blue ff-arial'}>
																		Descuento.
																		${FieldsJs.Currency(item.venta_descuento)}
																	</p>
																) : ''}
															</Fragment>
														</td>
													</tr>
													<tr className={key % 2 === 0 ? 'bg-lightgray' : 'bg-white'}>
														<td className={'padding-4'} colSpan={9}>
															
															{(item.venta_producto_tipo_requerimiento || []).map((itemTR, keyTR) => (
																<div key={keyTR}
																     className={'vertical-inline b-r-20 margin-5 ' + (disabledInputVentaEditar ? 'bg-gray' : 'bg-danger')}>
																	<div
																		className={'vertical-inline padding-3 padding-10-L padding-10-R v-center text-white'}>
																		{itemTR.tipo_requerimiento || 'N/A'} {itemTR.costo_adicional > 0 ? '$' + FieldsJs.Currency(itemTR.costo_adicional) : ''}
																	</div>
																	<div
																		className={'vertical-inline padding-3 padding-10-R v-center text-white'}>
																		{!disabledInputVentaEditar ? (
																			<DeleteOutlined className={'px-18'}
																			                onClick={() => this.props.eliminar_requerimiento(key, keyTR)}/>
																		) : ''}
																	</div>
																</div>
															))}
															
															{(item.venta_producto_requerimiento_adicional || []).map((itemTR, keyTR) => (
																<div key={keyTR}
																     className={'vertical-inline b-r-20 margin-5 ' + (disabledInputVentaEditar ? 'bg-gray' : 'bg-danger')}>
																	<div
																		className={'vertical-inline padding-3 padding-10-L padding-10-R v-center text-white'}>
																		{itemTR.requerimiento_adicional || 'N/A'} {itemTR.costo_adicional > 0 ? '$' + FieldsJs.Currency(itemTR.costo_adicional) : ''}
																	</div>
																	<div
																		className={'vertical-inline padding-3 padding-10-R v-center text-white'}>
																		{!disabledInputVentaEditar ? (
																			<DeleteOutlined className={'px-18'}
																			                onClick={() => this.props.eliminar_producto_atributo(key, keyTR)}/>
																		) : ''}
																	</div>
																</div>
															))}
															
															{!disabledInputVentaEditar ? (
																<ModalAtributoRroducto
																	index={key}
																	icono={(
																		<BtnMasAdd/>
																	)}
																	agregar_producto_atributo={this.props.agregar_producto_atributo}
																	showSnackBars={this.props.showSnackBars}
																/>
															) : ''}
														
														</td>
													</tr>
												</Fragment>
											))}
											{this.props.productos.length <= 0 ? (
												<tr className={'bg-white'}>
													<td className={'padding-0 text-center'} colSpan={9}>
														<VistaVacia numero={2} height={'100px'} width={'100px'}
														            padding={'30px'}
														            mensaje={'Sin productos agregados'}/>
													</td>
												</tr>
											) : null}
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={6} rowSpan={5}
												    align={'center'}>
												</td>
												<td className={'padding-4'} colSpan={2}>Subtotal</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.subtotal)}
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={2}>IVA {desglose.iva} %</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.iva_total)}
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={2}>Descuento</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.descuento)}
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={2}>Total sin descuento</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.total)}
													<hr/>
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={2}>TOTAL</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.total - desglose.descuento)}
												</td>
											</tr>
											</tbody>
										</table>
									
									</article>
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}
								      className={'h-160-px borde-punteado-gray-1 bg-lightgray padding-10'}>
									
									<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-left'}>
											<Grid container spacing={0}>
												<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
													<Typography variant={'h6'} className={'text-black px-12'}>
														Todo trabajo requiere un 50 % de anticipo
													</Typography>
												</Grid>
												<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
													<Typography variant={'h6'} className={'text-black px-12'}>
														Precios sujetos a cambio sin previo aviso
													</Typography>
												</Grid>
												<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
													<Typography variant={'h6'} className={'text-black px-10'}>
														¡DISEÑAMOS AL GUSTO DE NUESTROS CLIENTES!
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{padding: 0}}>
											<Divider/>
										</Grid>
										
										<Grid item className={'text-left'}
										      xs={12}
										      sm={5}
										      md={5}
										      lg={5}
										      xl={5}>
											<TextField
												rows={5}
												inputProps={{
													style: {
														fontSize: '13px',
														lineHeight: '13px',
														padding: '0px',
														margin: '0px',
													}
												}}
												className={'px-12 padding-0 margin-0'}
												variant={"filled"}
												multiline
												fullWidth
												onChange={this.props.handleChange}
												type="text"
												margin="none"
												
												helperText=""
												name="observaciones"
												label="Observaciones"
												value={this.props.form.observaciones}
												disabled={disabledInputVentaEditar}
											/>
										</Grid>
										
										<Grid item className={'text-left'}
										      xs={12}
										      sm={3}
										      md={3}
										      lg={3}
										      xl={3}>
											
											<FormGroup row>
												<FormControlLabel
													className={'h-40-px px-12 text-left l-h-12-px'}
													control={
														<Checkbox
															fontSize="small"
															type="checkbox"
															color="primary"
															name="cliente_deja_anexo"
															onChange={(e) => this.props.handleChange(e)}
															checked={!!this.props.form.cliente_deja_anexo}
															disabled={disabledInputVentaEditar}
														/>
													}
													label="Cliente deja anexo imagen de propuesta"
													labelPlacement="end"
												/>
											</FormGroup>
											
											<FormGroup row>
												<FormControlLabel
													className={'h-40-px px-12 text-left l-h-12-px'}
													control={
														<Checkbox
															fontSize="small"
															type="checkbox"
															color="primary"
															name="cliente_envia_muestra_por_correo"
															onChange={(e) => this.props.handleChange(e)}
															checked={!!this.props.form.cliente_envia_muestra_por_correo}
															disabled={disabledInputVentaEditar}
														/>
													}
													label="Cliente envía muestra por correo electrónico"
													labelPlacement="end"
												/>
											</FormGroup>
										
										</Grid>
										
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={'text-left'}>
											
											<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
												
												<Grid item xs={5} sm={5} md={5} lg={5} xl={5}
												      className={'text-left'}>
													<TextField
														multiline
														fullWidth
														type="text"
														margin="none"
														className={classes.root}
														inputProps={{
															className: classes.input,
															placeholder: "0",
															maxLength: 10
														}}
														onKeyPress={(e) => {
															MinMax(e, 0, 100);
															EnteroSolo(e);
														}}
														
														label=""
														helperText={'% descuento'}
														name={'porcentaje_descuento'}
														value={this.props.form.porcentaje_descuento}
														onChange={(e) => this.props.handleChange(e)}
														// disabled={disabledInputVentaEditar}
														disabled={true}
													/>
												</Grid>
												
												<Grid item xs={7} sm={7} md={7} lg={7} xl={7}
												      className={'text-left'}>
													<KeyboardDatePicker
														format={'dd/MM/yyyy'} fullWidth required clearable
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'change date',
															disabled: disabledInputVentaEditar
														}}
														label=""
														helperText={'Fecha entrega'}
														value={this.props.form.fecha_entrega}
														onChange={(date) => {
															this.props.handleChange(null, null, null, date, 'fecha_entrega');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														disabled={disabledInputVentaEditar}
													/>
												</Grid>
												
												<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={"right"}>
													<Button
														fullWidth
														variant="contained"
														onClick={() => {
															let smg = "";
															if (!disabledInputVentaEditar && !disabledInputVenta === false) {
																smg = "¿Estas seguro de salir de la modificación de la venta, se perderán los cambios realizados?";
															} else {
																if (!disabledInputVenta) {
																	smg = "¿Estas seguro de salir de la configuración de la venta, se perderán los datos capturados?";
																} else {
																	smg = "¿Deseas salir de la vista previa de la orden de venta?";
																}
															}
															PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, smg).then((r) => {
																if (r.button === 'Aceptar') {
																	window.history.back();
																}
															});
														}}
														style={{
															backgroundColor: "white",
															color: "black",
															textTransform: "none",
															padding: "7px 0px",
															fontSize: "13px",
														}}
													>
														{!disabledInputVentaEditar && !disabledInputVenta === false ? "Atras" : (!disabledInputVenta ? "Cancelar orden" : "Regresar")}
													</Button>
												</Grid>
												
												{!disabledInputVentaEditar && !disabledInputVenta === false ? (
													<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={"right"}>
														<Button
															fullWidth
															variant="contained"
															onClick={this.props.OrdenDeVentaActualizar}
															style={{
																backgroundColor: "#595959",
																color: "white",
																textTransform: "none",
																padding: "4px 0px",
																fontSize: "16px",
															}}
														>
															Actualizar
														</Button>
													</Grid>
												) : (
													<Fragment>
														{!disabledInputVenta ? (
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}
															      align={"right"}>
																<Button
																	fullWidth
																	variant="contained"
																	onClick={this.props.OrdenDeVentaContinuar}
																	style={{
																		backgroundColor: "#595959",
																		color: "white",
																		textTransform: "none",
																		padding: "4px 0px",
																		fontSize: "16px",
																	}}
																>
																	Continuar
																</Button>
															</Grid>
														) : null}
													</Fragment>
												)}
											
											</Grid>
										
										</Grid>
									
									</Grid>
								
								</Grid>
							
							</Grid>
						
						</Grid>
					
					</Grid>
				
				</section>
			
			</Fragment>
		);
	}
}

ContenidoOrdenVenta.propTypes = {
	classes: PropTypes.object.isRequired,
	RefreshListCliente: PropTypes.func.isRequired,
	
	handleChange: PropTypes.func.isRequired,
	updatePrecio: PropTypes.func.isRequired,
	
	mas: PropTypes.func.isRequired,
	menos: PropTypes.func.isRequired,
	
	eliminar_requerimiento: PropTypes.func.isRequired,
	eliminar_producto_atributo: PropTypes.func.isRequired,
	agregar_producto_atributo: PropTypes.func.isRequired,
	
	producto_requerimiento_adicional: PropTypes.array.isRequired,
	
	agregar_producto_requerimiento_adicional: PropTypes.func.isRequired,
	eliminar_producto_requerimiento_adicional: PropTypes.func.isRequired,
	
	cat_como_te_enteraste: PropTypes.array.isRequired,
	cat_tipo_requerimiento: PropTypes.array.isRequired,
	productos: PropTypes.array.isRequired,
	cat_estado: PropTypes.array.isRequired,
	cat_municipio: PropTypes.array.isRequired,
	cat_grupo: PropTypes.array.isRequired,
	suggestions: PropTypes.array.isRequired,
	handleChangeSingle: PropTypes.func.isRequired,
	updateDesglose: PropTypes.func.isRequired,
	
	OrdenDeVentaContinuar: PropTypes.func.isRequired,
	OrdenDeVentaGuardar: PropTypes.func.isRequired,
	OrdenDeVentaActualizar: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContenidoOrdenVenta);
