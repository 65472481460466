import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {AdministrativoService} from "../../../../services/_Sis/AdministrativoService/AdministrativoService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	DeviceHubOutlined,
	AlternateEmailOutlined,
	HdrWeakOutlined,
	HdrStrongOutlined,
	PhoneOutlined,
	CalendarTodayOutlined,
	TurnedInNotOutlined,
	Home,
	Business,
	LocalHospital,
	School
} from "@material-ui/icons";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {CONFIG} from "../../../../settings/Config/Config";
import {PopupService} from "../../../../settings/PoPup/PoPup";


class ModalAdministrativo extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_administrativo: (props.item || {}).id_administrativo,
			curp: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			id_cat_sexo: '',
			correo_electronico: '',
			fecha_nacimiento: '',
			domicilio: '',
			fecha_ingreso: '',
			tipo_categoria: '',
			id_cat_curso: '',
			id_cat_tipo_plaza: '',
			formacion_academica: '',
			activo: true,
			
			list_cat_sexo: [],
			list_cat_tipo_plaza: [],
			list_cat_curso: [],
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.ListSexo();
		this.ListCurso();
		this.ListTipoPlaza();
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_administrativo > 0 || FieldsJs.Field(this.state.id_administrativo)) {
			AdministrativoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			AdministrativoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_administrativo: '',
			curp: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			id_cat_sexo: '',
			correo_electronico: '',
			fecha_nacimiento: null,
			domicilio: '',
			fecha_ingreso: null,
			tipo_categoria: '',
			id_cat_curso: '',
			id_cat_tipo_plaza: '',
			formacion_academica: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_administrativo: item.id_administrativo || '',
			curp: item.curp || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			telefono: item.telefono || '',
			id_cat_sexo: item.id_cat_sexo || '',
			correo_electronico: item.correo_electronico || '',
			fecha_nacimiento: item.fecha_nacimiento || null,
			domicilio: item.domicilio || '',
			fecha_ingreso: item.fecha_ingreso || null,
			tipo_categoria: item.tipo_categoria || '',
			id_cat_curso: item.id_cat_curso || '',
			id_cat_tipo_plaza: item.id_cat_tipo_plaza || '',
			formacion_academica: item.formacion_academica || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_administrativo: item.id_administrativo || '',
			curp: item.curp || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			telefono: item.telefono || '',
			id_cat_sexo: item.id_cat_sexo || '',
			correo_electronico: item.correo_electronico || '',
			fecha_nacimiento: item.fecha_nacimiento || null,
			domicilio: item.domicilio || '',
			fecha_ingreso: item.fecha_ingreso || null,
			tipo_categoria: item.tipo_categoria || '',
			id_cat_curso: item.id_cat_curso || '',
			id_cat_tipo_plaza: item.id_cat_tipo_plaza || '',
			formacion_academica: item.formacion_academica || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	ListSexo = () => {
		CatService.ListSexo().then((response) => {
			this.setState({
				list_cat_sexo: response.data
			});
		});
	}
	
	ListCurso = () => {
		CatService.ListCurso().then((response) => {
			this.setState({
				list_cat_curso: response.data
			});
		});
	}
	
	ListTipoPlaza = () => {
		CatService.ListTipoPlaza().then((response) => {
			this.setState({
				list_cat_tipo_plaza: response.data
			});
		});
	}
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_administrativo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Administrativo'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									type={'text'}
									fullWidth
									name="curp"
									onChange={this.handleChange}
									// helperText="Requerido"
									label="CURP" autoComplete={'off'}
									value={this.state.curp}
									inputProps={{maxLength: 18}}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="nombre"
										           onChange={this.handleChange}
										           label="Nombre(s)" autoComplete={'off'}
										           value={this.state.nombre}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="apellido_paterno"
										           onChange={this.handleChange}
										           label="Apellido Paterno" autoComplete={'off'}
										           value={this.state.apellido_paterno}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="apellido_materno"
										           onChange={this.handleChange}
										           label="Apellido Materno" autoComplete={'off'}
										           value={this.state.apellido_materno}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PhoneOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="telefono"
										           onChange={this.handleChange}
										           label="Teléfono" autoComplete={'off'}
										           value={this.state.telefono}
										           inputProps={{maxLength: 10}}
										           onKeyPress={EnteroSolo}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<DeviceHubOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_sexo"
											label="Sexo"
											value={this.state.id_cat_sexo}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_sexo.map(option => (
												<option key={option.id_cat_sexo}
												        value={option.id_cat_sexo}>
													{option.sexo}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AlternateEmailOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="correo_electronico"
										           onChange={this.handleChange}
										           label="Correo electrónico" autoComplete={'off'}
										           value={this.state.correo_electronico}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<CalendarTodayOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											label="Fecha nacimiento"
											value={this.state.fecha_nacimiento}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_nacimiento');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-start"}>
									<Grid item className={'w-30-px'}>
										<Home className={'w-100 text-gray margin-20-T'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											multiline
											rows={3}
											name="domicilio"
											onChange={this.handleChange}
											label="Domicilio" autoComplete={'off'}
											value={this.state.domicilio}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider className={'margin-10-T'}/>
							</Grid>
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Typography variant={'h5'} className={'px-14 margin-15-T text-left'}>
									Datos del tutor
								</Typography>
							
							</Grid>*/}
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<CalendarTodayOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											label="Año de Ingreso"
											value={this.state.fecha_ingreso}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_ingreso');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											name="tipo_categoria"
											label="Tipo de Categoria"
											type="text"
											fullWidth
											value={this.state.tipo_categoria}
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_tipo_plaza"
											label="Tipo Plaza"
											value={this.state.id_cat_tipo_plaza}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_tipo_plaza.map(option => (
												<option key={option.id_cat_tipo_plaza}
												        value={option.id_cat_tipo_plaza}>
													{option.tipo_plaza}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_curso"
											label="Curso"
											value={this.state.id_cat_curso}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_curso.map(option => (
												<option key={option.id_cat_curso}
												        value={option.id_cat_curso}>
													{option.curso}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-start"}>
									<Grid item className={'w-30-px'}>
										<School className={'w-100 text-gray margin-20-T'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											multiline
											rows={3}
											name="formacion_academica"
											onChange={this.handleChange}
											label="Formación Academica" autoComplete={'off'}
											value={this.state.formacion_academica}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_administrativo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalAdministrativo.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalAdministrativo;
